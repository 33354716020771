import ReactECharts from "echarts-for-react";

const PieChart = () => {
    const options = {
        tooltip: {
            trigger: "item",
        },
        legend: {
            top: "5%",
            left: "center",
        },
        series: [
            {
                name: "Dashboard Chart",
                type: "pie",
                radius: ["55%", "65%"],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: "#fff",
                    borderWidth: 2,
                },
                label: {
                    show: false,
                    position: "center",
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: "40",
                        fontWeight: "bold",
                    },
                },
                labelLine: {
                    show: false,
                },
                data: [
                    { value: 1048, name: "Test A" },
                    { value: 735, name: "Test B" },
                    { value: 580, name: "Test C" },
                ],
            },
        ],
    };

    if (!options) return <div> Loading....</div>;

    return (
        <div className="min-w-0 p-4 bg-white rounded-lg shadow flex flex-col items-center">
            <div className="flex justify-items-start w-full">
                <h4 className="mb-1 font-semibold text-gray-800">Doughnut/Pie</h4>
            </div>
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        display: " block",
                        width: "397px",
                        height: "280px",
                    }}
                />
            )}
        </div>
    );
};

export default PieChart;
