import { BrowserRouter } from "react-router-dom";
import Routes from "./components/Router";

const App = () => {
    return (
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    );
};

export default App;
