const Logo = () => (
    <svg
        width="201"
        height="14"
        viewBox="0 0 244 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M125.752 15.9848C126.686 15.9848 127.496 15.1711 127.503 14.1597C127.496 13.1635 126.686 12.3498 125.752 12.3498C124.788 12.3498 123.993 13.1635 124 14.1597C123.993 15.1711 124.788 15.9848 125.752 15.9848Z"
            fill="white"
        />
        <path
            d="M130.114 15.7871H140.214V13.0722H133.274V9.35361H139.666V6.63878H133.274V2.92776H140.185V0.212928H130.114V15.7871Z"
            fill="white"
        />
        <path
            d="M145.66 0.212928H142.077L146.667 8L141.989 15.7871H145.609L148.674 10.4715H148.79L151.856 15.7871H155.49L150.827 8L155.388 0.212928H151.819L148.79 5.52091H148.674L145.66 0.212928Z"
            fill="white"
        />
        <path
            d="M169.892 5.6654C169.476 2.06844 166.863 0 163.404 0C159.456 0 156.442 2.90494 156.442 8C156.442 13.0798 159.405 16 163.404 16C167.235 16 169.549 13.346 169.892 10.4791L166.695 10.4639C166.396 12.1293 165.141 13.0875 163.455 13.0875C161.186 13.0875 159.646 11.3308 159.646 8C159.646 4.76046 161.164 2.91255 163.477 2.91255C165.207 2.91255 166.455 3.95437 166.695 5.6654H169.892Z"
            fill="white"
        />
        <path
            d="M172.158 15.7871H175.318V9.35361H181.732V15.7871H184.885V0.212928H181.732V6.63878H175.318V0.212928H172.158V15.7871Z"
            fill="white"
        />
        <path
            d="M190.066 15.7871L191.176 12.2357H196.569L197.678 15.7871H201.064L195.912 0.212928H191.84L186.68 15.7871H190.066ZM191.978 9.6654L193.818 3.77186H193.934L195.773 9.6654H191.978Z"
            fill="white"
        />
        <path
            d="M215.364 0.212928H212.219V10.0076H212.087L205.636 0.212928H202.863V15.7871H206.023V5.98479H206.132L212.635 15.7871H215.364V0.212928Z"
            fill="white"
        />
        <path
            d="M227.885 5.24715H231.096C230.687 2.1673 228.082 0 224.703 0C220.755 0 217.712 2.96578 217.712 8.01521C217.712 12.943 220.558 16 224.769 16C228.542 16 231.235 13.5133 231.235 9.41445V7.45247H224.981V9.93156H228.192C228.148 11.8631 226.885 13.0875 224.784 13.0875C222.412 13.0875 220.916 11.2395 220.916 7.98479C220.916 4.74525 222.47 2.91255 224.754 2.91255C226.382 2.91255 227.484 3.78707 227.885 5.24715Z"
            fill="white"
        />
        <path
            d="M233.608 15.7871H243.708V13.0722H236.768V9.35361H243.161V6.63878H236.768V2.92776H243.679V0.212928H233.608V15.7871Z"
            fill="white"
        />
        <path
            d="M12.466 3.43237C14.9606 5.92694 14.9606 9.97143 12.466 12.466C9.97143 14.9606 5.92694 14.9606 3.43237 12.466C0.937805 9.97143 0.937805 5.92694 3.43237 3.43237C5.92694 0.937805 9.97143 0.937805 12.466 3.43237Z"
            stroke="white"
            strokeWidth="3.12289"
        />
        <path
            d="M15.9695 13.6886L11.3797 9.08496M13.6746 15.9904L9.08475 11.3868"
            stroke="#F07B39"
            strokeWidth="1.99325"
        />
        <path
            d="M17.8856 8C17.8856 6.78136 18.0864 5.67742 18.4878 4.68817C18.9036 3.69892 19.477 2.86021 20.2082 2.17204C20.9394 1.46953 21.8068 0.931899 22.8104 0.559139C23.814 0.18638 24.9107 0 26.1007 0C27.2907 0 28.3874 0.18638 29.391 0.559139C30.3946 0.931899 31.262 1.46953 31.9932 2.17204C32.7243 2.86021 33.2907 3.69892 33.6921 4.68817C34.1079 5.67742 34.3157 6.78136 34.3157 8C34.3157 9.21864 34.1079 10.3226 33.6921 11.3118C33.2907 12.3011 32.7243 13.147 31.9932 13.8495C31.262 14.5376 30.3946 15.0681 29.391 15.4409C28.3874 15.8136 27.2907 16 26.1007 16C24.9107 16 23.814 15.8136 22.8104 15.4409C21.8068 15.0681 20.9394 14.5376 20.2082 13.8495C19.477 13.147 18.9036 12.3011 18.4878 11.3118C18.0864 10.3226 17.8856 9.21864 17.8856 8ZM21.3695 8C21.3695 8.71685 21.477 9.37634 21.6921 9.97849C21.9215 10.5663 22.2441 11.0824 22.6598 11.5269C23.0756 11.957 23.5702 12.2939 24.1437 12.5376C24.7315 12.7814 25.3838 12.9032 26.1007 12.9032C26.8175 12.9032 27.4627 12.7814 28.0362 12.5376C28.624 12.2939 29.1258 11.957 29.5416 11.5269C29.9573 11.0824 30.2727 10.5663 30.4878 9.97849C30.7172 9.37634 30.8319 8.71685 30.8319 8C30.8319 7.29749 30.7172 6.64516 30.4878 6.04301C30.2727 5.44086 29.9573 4.92473 29.5416 4.49462C29.1258 4.05018 28.624 3.70609 28.0362 3.46237C27.4627 3.21864 26.8175 3.09677 26.1007 3.09677C25.3838 3.09677 24.7315 3.21864 24.1437 3.46237C23.5702 3.70609 23.0756 4.05018 22.6598 4.49462C22.2441 4.92473 21.9215 5.44086 21.6921 6.04301C21.477 6.64516 21.3695 7.29749 21.3695 8Z"
            fill="white"
        />
        <path
            d="M36.1659 0.387097H40.725L47.1981 10.9677H47.2411V0.387097H50.596V15.6129H46.2089L39.5637 4.77419H39.5207V15.6129H36.1659V0.387097Z"
            fill="white"
        />
        <path
            d="M56.2181 3.35484H51.874V0.387097H63.9171V3.35484H59.573V15.6129H56.2181V3.35484Z"
            fill="white"
        />
        <path
            d="M65.3196 0.387097H75.6637V3.48387H68.6744V6.32258H75.2766V9.41935H68.6744V12.5161H76.0508V15.6129H65.3196V0.387097Z"
            fill="white"
        />
        <path
            d="M78.3063 0.387097H82.8655L89.3386 10.9677H89.3816V0.387097H92.7365V15.6129H88.3493L81.7042 4.77419H81.6612V15.6129H78.3063V0.387097Z"
            fill="white"
        />
        <path
            d="M98.3586 3.35484H94.0145V0.387097H106.058V3.35484H101.713V15.6129H98.3586V3.35484Z"
            fill="white"
        />
        <path
            d="M106.514 8C106.514 6.78136 106.715 5.67742 107.116 4.68817C107.532 3.69892 108.105 2.86021 108.836 2.17204C109.568 1.46953 110.435 0.931899 111.439 0.559139C112.442 0.18638 113.539 0 114.729 0C115.919 0 117.016 0.18638 118.019 0.559139C119.023 0.931899 119.89 1.46953 120.621 2.17204C121.353 2.86021 121.919 3.69892 122.32 4.68817C122.736 5.67742 122.944 6.78136 122.944 8C122.944 9.21864 122.736 10.3226 122.32 11.3118C121.919 12.3011 121.353 13.147 120.621 13.8495C119.89 14.5376 119.023 15.0681 118.019 15.4409C117.016 15.8136 115.919 16 114.729 16C113.539 16 112.442 15.8136 111.439 15.4409C110.435 15.0681 109.568 14.5376 108.836 13.8495C108.105 13.147 107.532 12.3011 107.116 11.3118C106.715 10.3226 106.514 9.21864 106.514 8ZM109.998 8C109.998 8.71685 110.105 9.37634 110.32 9.97849C110.55 10.5663 110.872 11.0824 111.288 11.5269C111.704 11.957 112.198 12.2939 112.772 12.5376C113.36 12.7814 114.012 12.9032 114.729 12.9032C115.446 12.9032 116.091 12.7814 116.664 12.5376C117.252 12.2939 117.754 11.957 118.17 11.5269C118.586 11.0824 118.901 10.5663 119.116 9.97849C119.345 9.37634 119.46 8.71685 119.46 8C119.46 7.29749 119.345 6.64516 119.116 6.04301C118.901 5.44086 118.586 4.92473 118.17 4.49462C117.754 4.05018 117.252 3.70609 116.664 3.46237C116.091 3.21864 115.446 3.09677 114.729 3.09677C114.012 3.09677 113.36 3.21864 112.772 3.46237C112.198 3.70609 111.704 4.05018 111.288 4.49462C110.872 4.92473 110.55 5.44086 110.32 6.04301C110.105 6.64516 109.998 7.29749 109.998 8Z"
            fill="white"
        />
    </svg>
);
export default Logo;
