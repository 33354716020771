import { ChangeEventHandler, useEffect, useState } from "react";
import GreenCheck from "src/components/shared/icons/greenCheck";

interface Props {
    newPassword: string;
    handleChange: ChangeEventHandler;
}

const NewPasswordField = ({ newPassword, handleChange }: Props) => {
    const [selected, setSelected] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        if (
            /\W|_/.test(newPassword) &&
            /[A-Z]/.test(newPassword) &&
            /[a-z]/.test(newPassword) &&
            /\d/.test(newPassword) &&
            newPassword.length > 7
        ) {
            setError(false);
        } else {
            setError(true);
        }
    }, [newPassword, error]);
    return (
        <div className="mb-4">
            <label
                htmlFor="newPassword"
                className="text-gray-700 dark:text-gray-400 text-sm font-medium"
            >
                New Password
            </label>
            <input
                type="password"
                id="newPassword"
                name="newPassword"
                value={newPassword}
                onChange={handleChange}
                className="block rounded-lg p-2 w-full mt-1 text-sm border dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                minLength={8}
                maxLength={30}
                onFocus={() => {
                    setSelected(true);
                }}
                onBlur={() => {
                    setSelected(false);
                }}
            />
            {selected ? (
                <div>
                    <div
                        className={
                            newPassword.length > 7
                                ? "flex text-green-500 items-center pb-2"
                                : "flex items-center pb-2"
                        }
                    >
                        {newPassword.length > 7 ? (
                            <GreenCheck />
                        ) : (
                            <div className="ml-2 h-2 w-2 rounded-lg bg-black"></div>
                        )}{" "}
                        <span className="pl-2">Atleast 8 characters</span>
                    </div>
                    <div
                        className={
                            /[A-Z]/.test(newPassword)
                                ? "flex text-green-500 items-center pb-2"
                                : "flex items-center pb-2"
                        }
                    >
                        {/[A-Z]/.test(newPassword) ? (
                            <GreenCheck />
                        ) : (
                            <div className="ml-2 h-2 w-2 rounded-lg bg-black"></div>
                        )}{" "}
                        <span className="pl-2">Atleast 1 uppercase letter</span>
                    </div>
                    <div
                        className={
                            /[a-z]/.test(newPassword)
                                ? "flex text-green-500 items-center pb-2"
                                : "flex items-center pb-2"
                        }
                    >
                        {/[a-z]/.test(newPassword) ? (
                            <GreenCheck />
                        ) : (
                            <div className="ml-2 h-2 w-2 rounded-lg bg-black"></div>
                        )}{" "}
                        <span className="pl-2">Atleast 1 lowercase letter</span>
                    </div>
                    <div
                        className={
                            /\W|_/.test(newPassword)
                                ? "flex text-green-500 items-center pb-2 "
                                : "flex items-center pb-2"
                        }
                    >
                        {/\W|_/.test(newPassword) ? (
                            <GreenCheck />
                        ) : (
                            <div className="ml-2 h-2 w-2 rounded-lg bg-black"></div>
                        )}{" "}
                        <span className="pl-2">Atleast 1 special character </span>
                    </div>
                    <div
                        className={
                            /\d/.test(newPassword)
                                ? "flex text-green-500 items-center pb-2"
                                : "flex items-center pb-2"
                        }
                    >
                        {/\d/.test(newPassword) ? (
                            <GreenCheck />
                        ) : (
                            <div className="ml-2 h-2 w-2 rounded-lg bg-black"></div>
                        )}{" "}
                        <span className="pl-2">Atleast 1 numeric character</span>
                    </div>
                </div>
            ) : newPassword !== "" && error ? (
                <div>
                    <div
                        className={
                            newPassword.length > 7
                                ? "flex text-green-500 items-center pb-2"
                                : "flex text-red-500 items-center pb-2"
                        }
                    >
                        {newPassword.length > 7 ? (
                            <GreenCheck />
                        ) : (
                            <div className="ml-2 h-2 w-2 rounded-lg bg-red-500"></div>
                        )}{" "}
                        <span className="pl-2">Atleast 8 characters</span>
                    </div>
                    <div
                        className={
                            /[A-Z]/.test(newPassword)
                                ? "flex text-green-500 items-center pb-2"
                                : "flex text-red-500 items-center pb-2"
                        }
                    >
                        {/[A-Z]/.test(newPassword) ? (
                            <GreenCheck />
                        ) : (
                            <div className="ml-2 h-2 w-2 rounded-lg bg-red-500"></div>
                        )}{" "}
                        <span className="pl-2">Atleast 1 uppercase letter</span>
                    </div>
                    <div
                        className={
                            /[a-z]/.test(newPassword)
                                ? "flex text-green-500 items-center pb-2"
                                : "flex text-red-500 items-center pb-2"
                        }
                    >
                        {/[a-z]/.test(newPassword) ? (
                            <GreenCheck />
                        ) : (
                            <div className="ml-2 h-2 w-2 rounded-lg bg-red-500"></div>
                        )}{" "}
                        <span className="pl-2">Atleast 1 lowercase letter</span>
                    </div>
                    <div
                        className={
                            /\W|_/.test(newPassword)
                                ? "flex text-green-500 items-center pb-2 "
                                : "flex text-red-500 items-center pb-2"
                        }
                    >
                        {/\W|_/.test(newPassword) ? (
                            <GreenCheck />
                        ) : (
                            <div className="ml-2 h-2 w-2 rounded-lg bg-red-500"></div>
                        )}{" "}
                        <span className="pl-2">Atleast 1 special character </span>
                    </div>
                    <div
                        className={
                            /\d/.test(newPassword)
                                ? "flex text-green-500 items-center pb-2"
                                : "flex text-red-500 items-center pb-2"
                        }
                    >
                        {/\d/.test(newPassword) ? (
                            <GreenCheck />
                        ) : (
                            <div className="ml-2 h-2 w-2 rounded-lg bg-red-500"></div>
                        )}{" "}
                        <span className="pl-2">Atleast 1 numeric character</span>
                    </div>
                </div>
            ) : null}
        </div>
    );
};
export default NewPasswordField;
