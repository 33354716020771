import HOC from "../shared/HOC";
import BrandApplicationTable from "./Table";

const BrandApplicationsTab = () => {
  return (
    <HOC route="brand-application" title="Brand Applications">
      <BrandApplicationTable />
    </HOC>
  );
};

export default BrandApplicationsTab;
