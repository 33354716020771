import { useState, useEffect } from "react";

import { useQuery } from "@apollo/client";

import { getEmployeeListQuery } from "src/graphql/queries/brand";
import TableItem from "./tableItem";

/**
 *
 * User Table list
 */

const Table = () => {
    const [page, setPage] = useState<any>(1);

    // Function to get data from query
    const { data, loading, refetch } = useQuery(getEmployeeListQuery, {
        variables: { pageNo: page, pageSize: 20, sortByCreatedAt: "desc" },
    });

    // Function to refetch data when page changes
    useEffect(() => {
        refetch();
    }, [page]);

    // To show loading when loading or no data available
    if (loading || !data) {
        return <div> Loading... </div>;
    }

    const { employee } = data?.getEmployeeList;

    return (
        <div className="w-full overflow-hidden rounded-lg shadow-xs">
            <div className="w-full overflow-x-auto h-full">
                <table className="w-full whitespace-no-wrap">
                    <thead>
                        <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                            <th className="px-4 py-3">Users</th>
                            <th className="px-4 py-3">Status</th>
                            <th className="px-4 py-3">Role</th>
                            <th className="px-4 py-3">Date Joined</th>
                            <th className="px-4 py-3">More</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800 ">
                        {employee.map((member: any, index: any) => (
                            <TableItem key={index} member={member} />
                        ))}
                    </tbody>
                </table>
                <div className="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
                    <span className="flex items-center col-span-3">
                        Showing {`${20 * (page - 1) + 1} to ${page * 20}`}
                    </span>
                    <span className="col-span-1"></span>

                    <span className="flex col-span-4 mt-2">
                        <nav aria-label="Table navigation">
                            <ul className="inline-flex items-center">
                                <li>
                                    <button
                                        className="px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple"
                                        onClick={() => {
                                            if (page !== 1) {
                                                setPage(page - 1);
                                            }
                                        }}
                                        aria-label="Previous"
                                    >
                                        <svg
                                            aria-hidden="true"
                                            className="w-4 h-4 fill-current"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                clipRule="evenodd"
                                                fillRule="evenodd"
                                            ></path>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className={
                                            "px-3 py-1 text-white transition-colors duration-150 bg-purple-600 border border-r-0 border-purple-600 rounded-md focus:outline-none focus:shadow-outline-purple"
                                        }
                                    >
                                        {page}
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple"
                                        onClick={() => {
                                            if (employee.length > 19) {
                                                setPage(page + 1);
                                            }
                                        }}
                                        aria-label="Next"
                                    >
                                        <svg
                                            className="w-4 h-4 fill-current"
                                            aria-hidden="true"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                clipRule="evenodd"
                                                fillRule="evenodd"
                                            ></path>
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Table;
