import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { useApolloClient } from "@apollo/client";
import { GetBrandDetails } from "src/graphql/queries/brand";
import Sidebar from "../../admin/Sidebar";
import { GetBrandApplication } from "src/graphql/queries/list";
import QoinIcon from "src/components/shared/icons/qoinIcon";

const BrandInfoTab = () => {
    const [brandDetails, setBrandDetails] = useState<any>();
    const [applicationDetails, setApplicationDetails] = useState<any>();
    const { id } = useParams() as any;

    const client = useApolloClient();

    const getApplicationData = async (id: string) => {
        try {
            const res = await client.query({
                query: GetBrandApplication,
                variables: { applicationId: id },
                fetchPolicy: "network-only",
            });

            const { getBrandApplication } = res.data;
            const { brandApplication } = getBrandApplication;
            setApplicationDetails(brandApplication);
        } catch (err) {
            console.log(err);
        }
    };

    const getData = async () => {
        try {
            const res = await client.query({
                query: GetBrandDetails,
                variables: { brandId: id },
                fetchPolicy: "network-only",
            });

            const { getBrandForBrandOrAdmin } = res.data;
            const { brand } = getBrandForBrandOrAdmin;
            setBrandDetails(brand);
            getApplicationData(brand?.applicationId);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="flex flex-row flex-1 w-full h-full ">
            <Sidebar route="brand" />
            <div className="flex flex-col pt-5 w-full flex-1 bg-gray-50">
                <div className="flex items-start justify-between mx-6">
                    <h1 className="p-2 text-xl font-semibold">{brandDetails?.brandName}</h1>
                    {/* <div className="p-2 text-xl">
                        <span className="font-medium">Accepted by :</span>
                        <span> Content Manager</span>
                        <div>
                            <span className="font-medium">Acceptance Date : </span>
                            <span> 04/08/2021</span>
                        </div>
                    </div> */}
                </div>
                <main className="h-full overflow-y-auto container">
                    <div className="container mb-10 px-8 mx-auto grid">
                        <div className="block text-base mb-4">
                            <span className="text-gray-700 dark:text-gray-300">Brand Type:</span>
                            <p className="font-semibold capitalize">{applicationDetails?.type}</p>
                        </div>
                        <hr />
                        <h6 className="text-lg my-4">General Information</h6>
                        <div className="flex  mb-8">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    First Name:
                                </span>
                                <p className="font-semibold">{applicationDetails?.firstName}</p>
                            </div>
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">Last Name:</span>
                                <p className="font-semibold">{applicationDetails?.lastName}</p>
                            </div>
                        </div>
                        <div className="flex justify-start mb-8">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Brand Name:
                                </span>
                                <p className="font-semibold">{brandDetails?.brandName}</p>
                            </div>

                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">Country:</span>
                                <p className="font-semibold">{applicationDetails?.country}</p>
                            </div>
                        </div>

                        <div className="flex mb-8">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Your Email:
                                </span>
                                <p className="font-semibold">{applicationDetails?.email}</p>
                            </div>
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Phone Number:
                                </span>
                                <p className="font-semibold">{applicationDetails?.mobile}</p>
                            </div>
                        </div>

                        <div className="flex mb-8">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    What type of content do you create?
                                </span>
                                <p className="font-semibold">
                                    {applicationDetails?.contentType.join(", ")}
                                </p>
                            </div>
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Content Genre:
                                </span>
                                <p className="font-semibold">{brandDetails?.genres.join(", ")}</p>
                            </div>
                        </div>
                        <div className="flex mb-8">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Website URL
                                </span>
                                <p className="font-semibold">
                                    {applicationDetails?.websiteUrl ? (
                                        <a
                                            href={applicationDetails?.websiteUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {applicationDetails.websiteUrl}
                                        </a>
                                    ) : (
                                        "N/A"
                                    )}
                                </p>
                            </div>

                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Social Media Links:
                                </span>
                                <p>
                                    {applicationDetails?.socialMediaUrls?.facebook && (
                                        <>
                                            <a
                                                href={applicationDetails?.socialMediaUrls?.facebook}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Facebook
                                            </a>
                                            <br />
                                        </>
                                    )}

                                    {applicationDetails?.socialMediaUrls?.twitter && (
                                        <>
                                            <a
                                                href={applicationDetails?.socialMediaUrls?.twitter}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Twitter
                                            </a>
                                            <br />
                                        </>
                                    )}

                                    {applicationDetails?.socialMediaUrls?.instagram && (
                                        <>
                                            <a
                                                href={
                                                    applicationDetails?.socialMediaUrls?.instagram
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Instagram
                                            </a>
                                            <br />
                                        </>
                                    )}

                                    {applicationDetails?.socialMediaUrls?.linkedin && (
                                        <a
                                            href={applicationDetails?.socialMediaUrls?.linkedin}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Linkedin
                                        </a>
                                    )}
                                    {applicationDetails?.socialMediaUrls?.other && (
                                        <a
                                            href={applicationDetails?.socialMediaUrls?.other}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Other
                                        </a>
                                    )}
                                </p>
                            </div>
                        </div>

                        <hr />

                        <h6 className="text-lg my-4">Content Information</h6>
                        <div className="flex justify-between">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">Total NFTs</span>
                                <p className="font-semibold">{brandDetails?.totalNfts}</p>
                            </div>

                            <div className="flex flex-1 text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    <div className="block text-base mr-5">
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Approved NFTs
                                        </span>
                                        <p className="font-semibold">
                                            {brandDetails?.approvedNfts}
                                        </p>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="flex justify-between mt-6">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Pending NFTs
                                </span>
                                <p className="font-semibold">{brandDetails?.pendingNfts}</p>
                            </div>

                            <div className="flex flex-1 text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    <div className="block text-base mr-5">
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Rejected NFTs
                                        </span>
                                        <p className="font-semibold">
                                            {brandDetails?.rejectedNfts}
                                        </p>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="flex justify-between my-6">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Total brand revenue
                                </span>
                                <p className="font-semibold flex items-center gap-1">
                                    <span>
                                        <QoinIcon color="black" size="15" />
                                    </span>
                                    {brandDetails?.totalRevenue}
                                </p>
                            </div>

                            <div className="flex flex-1 text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    <div className="block text-base mr-5">
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Total cohorts for brand
                                        </span>
                                        <p className="font-semibold">
                                            {brandDetails?.totalCohorts}
                                        </p>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <hr />

                        <h6 className="text-lg my-4">Additional Information (Optional)</h6>
                        <div className="flex justify-between">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    How Frequently do you create content?
                                </span>
                                <p className="font-semibold">
                                    {applicationDetails?.contentCreationFrequency}
                                </p>
                            </div>

                            <div className="flex flex-1 text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    <div className="block text-base mr-5">
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Where did you hear about us?
                                        </span>
                                        <p className="font-semibold">
                                            {applicationDetails?.whereDidYouHearAboutUs}
                                        </p>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default BrandInfoTab;
