import { useState } from "react";
import RecoveryPhrase from "./recoveyPhrase";
import ResetPassword from "./resetPassword";

const ForgotPasswordPage = () => {
    const [step, setStep] = useState(1);
    const [formData, setForm] = useState({ email: "", recoveryPhrase: "" });
    return (
        <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
            <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
                <div className="flex flex-col overflow-y-auto md:flex-row justify-center">
                    <div className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
                        {step === 1 ? (
                            <RecoveryPhrase
                                setStep={setStep}
                                formData={formData}
                                setForm={setForm}
                            />
                        ) : step === 2 ? (
                            <ResetPassword emailForm={formData} setStep={setStep} />
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ForgotPasswordPage;
