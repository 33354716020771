import { useState } from "react";
import { useMutation } from "@apollo/client";
import { SendInvite } from "src/graphql/mutation/auth";
import Cross from "../shared/icons/cross";

interface Props {
    setShow: Function;
}
const InviteUserModal = (props: Props) => {
    const { setShow } = props;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<any>(null);

    const handleChange = (e: any) => {
        setEmail(e.target.value);
    };

    const handlePassword = (e: any) => {
        setPassword(e.target.value);
    };

    const InviteMutation = useMutation(SendInvite)[0];
    const sendInvite = async () => {
        try {
            const res = await InviteMutation({
                variables: { email: email, role: "admin", password: password },
            });

            const { sendInvite } = res.data;
            const { ok, error } = sendInvite;
            if (ok) {
                setShow(false);
            } else {
                setError(error?.message);
            }
        } catch (err: any) {
            setError(err?.message);
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        sendInvite();
    };

    return (
        <div className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
            <div
                className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:w-96"
                role="dialog"
                id="modal"
            >
                <header className="flex justify-end">
                    <button
                        className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                        aria-label="close"
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        <Cross />
                    </button>
                </header>

                <div className="mt-4 mb-6">
                    <p className="mb-2 text-center text-lg font-semibold text-gray-700 dark:text-gray-300">
                        Invite new user
                    </p>

                    <form onSubmit={handleSubmit}>
                        <label className="block text-sm">
                            <span className="text-gray-700 dark:text-gray-400">
                                Email of New User
                            </span>
                            <input
                                className="block p-2 border w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                                placeholder="Jane Doe"
                                name="email"
                                value={email}
                                onChange={handleChange}
                            />
                        </label>
                        <label className="block text-sm">
                            <span className="text-gray-700 dark:text-gray-400">Your Password</span>
                            <input
                                className="block p-2 border w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                                placeholder="xxxxxxxx"
                                type="password"
                                name="password"
                                value={password}
                                onChange={handlePassword}
                            />
                        </label>
                        {error ? <div className="text-sm text-red-600">{error}</div> : null}
                        <button
                            type="submit"
                            className="block w-full px-4 py-2 mt-4 text-base font-medium leading-5 text-center text-white bg-green-600 border border-transparent rounded-lg  hover:bg-golden-50"
                        >
                            Invite User
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default InviteUserModal;
