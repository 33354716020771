import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { UpdateBrandStatus } from "src/graphql/mutation/brand";

interface Props {
  setModal: Function;
  id: string;
}
const RejectModal = (props: Props) => {
  const { setModal, id } = props;
  let history = useHistory();
  const RejectMutation = useMutation(UpdateBrandStatus)[0];
  const reject = async () => {
    try {
      const res = await RejectMutation({
        variables: {
          brandApplicationId: id,
          status: "rejected",
        },
      });

      const { updateBrandApplicationStatus } = res.data;
      const { ok } = updateBrandApplicationStatus;
      if (ok) {
        setModal(false);
        history.push("/brand-application");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleClick = () => {
    reject();
  };
  return (
    <div className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div
        // @click.away="closeModal"
        // @keydown.escape="closeModal"
        className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
        role="dialog"
        id="modal"
      >
        <header className="flex justify-end">
          <button
            className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
            aria-label="close"
            onClick={() => {
              setModal(false);
            }}
          >
            <svg
              className="w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              role="img"
              aria-hidden="true"
            >
              <path
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
                fillRule="evenodd"
              ></path>
            </svg>
          </button>
        </header>

        <div className="mt-4 text-center mb-6">
          <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300">
            Rejection Confirmation
          </p>
          <span className="font-medium">
            Are you sure if you want to reject this application?
          </span>
        </div>
        <footer className="flex flex-col items-center justify-center w-full px-6 py-3 -mb-4  sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
          <button
            onClick={() => {
              setModal(false);
            }}
            className="flex-1 px-5 py-3 text-sm font-medium leading-5 text-white text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 sm:px-4 sm:py-2  active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              handleClick();
            }}
            className="flex-1 px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg  sm:px-4 sm:py-2 active:bg-purple-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
          >
            Reject
          </button>
        </footer>
      </div>
    </div>
  );
};
export default RejectModal;
