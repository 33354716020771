import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Signin } from "src/graphql/mutation/auth";
import { GetUserData } from "src/graphql/queries/auth";
import { setUserData } from "src/redux/actions/action";

const Login = () => {
    const [formData, setForm] = useState({ email: "", password: "" });
    const [error, setError] = useState(null as any);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setForm((prevState) => {
            return { ...prevState, [name]: value };
        });
    };

    const client = useApolloClient();

    const dispatch = useDispatch();

    const getUserQuery = async () => {
        try {
            const res = await client.query({ query: GetUserData });
            const { getEmployeeDetails } = res.data;
            if (getEmployeeDetails.ok) {
                dispatch(
                    setUserData({
                        email: getEmployeeDetails.employee.email,
                        firstName: getEmployeeDetails.employee.firstName,
                        lastName: getEmployeeDetails.employee.lastName,
                        role: getEmployeeDetails.employee.role,
                    })
                );
            }
        } catch (err) {
            console.log(err);
        }
    };

    const SigninMutation = useMutation(Signin)[0];
    const startSignin = async () => {
        try {
            const res = await SigninMutation({
                variables: {
                    email: email,
                    password: password,
                },
            });
            const { employeeSignIn } = res.data;
            const { ok, error, authToken, refreshToken } = employeeSignIn;
            if (ok) {
                localStorage.setItem("accessToken", authToken);
                localStorage.setItem("refreshToken", refreshToken);
                getUserQuery();
            } else {
                setError(error?.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        startSignin();
    };

    const { email, password } = formData;

    return (
        <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
            <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
                <div className="flex flex-col overflow-y-auto md:flex-row justify-center">
                    <div className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
                        <div className="w-full">
                            <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                                Login
                            </h1>
                            <form onSubmit={handleSubmit}>
                                <label className="block text-sm">
                                    <span className="text-gray-700 dark:text-gray-400">Email</span>
                                    <input
                                        className="block p-2 w-full mt-1 text-sm border dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                                        placeholder="Jane Doe"
                                        name="email"
                                        value={email}
                                        onChange={handleChange}
                                    />
                                </label>
                                <label className="block mt-4 text-sm">
                                    <span className="text-gray-700 dark:text-gray-400">
                                        Password
                                    </span>
                                    <input
                                        className="block p-2 border w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                                        placeholder="***************"
                                        type="password"
                                        name="password"
                                        value={password}
                                        onChange={handleChange}
                                    />
                                </label>
                                <div className="my-2 text-purple-600 font-medium text-sm">
                                    <Link to="/forgot-password"> Forgot Password</Link>
                                </div>
                                {error ? <div className="text-red-600 text-sm">{error}</div> : null}
                                <button className="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple">
                                    Log in
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
