import HOC from "../shared/HOC";
import ChangePasswordForm from "./changePassword";

const SettingsPage = () => {
    return (
        <HOC route="settings" title="Settings">
            <ChangePasswordForm />
        </HOC>
    );
};
export default SettingsPage;
