import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { useApolloClient } from "@apollo/client";
import { GetBrandApplication } from "src/graphql/queries/list";

import Sidebar from "../admin/Sidebar";
import AcceptModal from "./acceptModal";
import RejectModal from "./rejectModal";

const BrandApplicationTab = () => {
    const [acceptModal, setAccept] = useState(false);
    const [rejectModal, setReject] = useState(false);
    const [brandDetails, setBrandDetails] = useState({
        firstName: "",
        lastName: "",
        email: "",
        type: "",
        status: "",
        contentType: [""],
        brandName: "",
        mobile: "",
        designation: "",
        sampleWorkUrls: [""],
        websiteUrl: "",
        whereDidYouHearAboutUs: "",
        socialMediaUrls: {} as any,
        country: "",
        contentCreationFrequency: "",
    });
    const {
        firstName,
        lastName,
        email,
        type,
        status,
        contentType,
        brandName,
        mobile,
        sampleWorkUrls,
        designation,
        websiteUrl,
        whereDidYouHearAboutUs,
        socialMediaUrls,
        country,
        contentCreationFrequency,
    } = brandDetails;

    const { id } = useParams() as any;

    const client = useApolloClient();

    const getData = async () => {
        try {
            const res = await client.query({
                query: GetBrandApplication,
                variables: { applicationId: id },
                fetchPolicy: "network-only",
            });

            const { getBrandApplication } = res.data;
            const { brandApplication } = getBrandApplication;
            setBrandDetails(brandApplication);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="flex flex-row flex-1 w-full h-full">
            {acceptModal && <AcceptModal id={id} setModal={setAccept} />}
            {rejectModal && <RejectModal id={id} setModal={setReject} />}
            <Sidebar route="brand" />
            <div className="flex flex-col pt-5 w-full flex-1 bg-gray-50">
                <div className="flex items-center justify-between mx-6">
                    <h1 className="p-2 text-xl font-semibold">{brandName}</h1>
                    {status === "pending" ? (
                        <div>
                            <button
                                onClick={() => {
                                    setAccept(true);
                                }}
                                className="p-2 px-4 bg-green-700 text-white rounded-md mr-2 cursor-pointer"
                            >
                                Approve
                            </button>
                            <button
                                onClick={() => {
                                    setReject(true);
                                }}
                                className="p-2 px-4 bg-red-700 text-white rounded-md cursor-pointer"
                            >
                                Reject
                            </button>
                        </div>
                    ) : (
                        <div className="capitalize">{status}</div>
                    )}
                </div>
                <main className="h-full overflow-y-auto container">
                    <div className="container px-8 mx-auto grid">
                        <div className="block text-base mb-4">
                            <span className="text-gray-700 dark:text-gray-300">Brand Type:</span>
                            <p className="font-semibold">{type}</p>
                        </div>
                        <hr />
                        <h6 className="text-lg my-4">General Information</h6>
                        <div className="flex  mb-8">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    First Name:
                                </span>
                                <p className="font-semibold">{firstName}</p>
                            </div>
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">Last Name:</span>
                                <p className="font-semibold">{lastName}</p>
                            </div>
                        </div>
                        <div className="flex justify-start mb-8">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Brand Name:
                                </span>
                                <p className="font-semibold">{brandName}</p>
                            </div>

                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">Country:</span>
                                <p className="font-semibold"> {country} </p>
                            </div>
                        </div>
                        <div className="flex justify-start mb-8">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Designation
                                </span>
                                <p className="font-semibold">{designation ? designation : "N/A"}</p>
                            </div>

                            <div className="flex flex-1 flex-col text-base mr-5"></div>
                        </div>

                        <div className="flex mb-8">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Your Email:
                                </span>
                                <p className="font-semibold"> {email} </p>
                            </div>
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Phone Number:
                                </span>
                                <p className="font-semibold">{mobile}</p>
                            </div>
                        </div>

                        <div className="flex mb-8">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    What type of content do you create?
                                </span>
                                <p className="font-semibold"> {contentType.join(", ")} </p>
                            </div>
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Sample Work Urls
                                </span>
                                <p className="font-semibold">
                                    {sampleWorkUrls.map((item: string, index: number) => (
                                        <>
                                            <a
                                                key={index}
                                                href={item}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {item.slice(0, 40)}
                                            </a>
                                            <br />
                                        </>
                                    ))}
                                </p>
                            </div>
                        </div>
                        <div className="flex mb-8">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Website Url
                                </span>
                                <p className="font-semibold">
                                    {websiteUrl ? (
                                        <a href={websiteUrl} target="_blank" rel="noreferrer">
                                            {websiteUrl}
                                        </a>
                                    ) : (
                                        "N/A"
                                    )}
                                </p>
                            </div>

                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Social Media Links:
                                </span>
                                <p className="font-semibold">
                                    {socialMediaUrls?.facebook && (
                                        <>
                                            <a
                                                href={socialMediaUrls?.facebook}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Facebook
                                            </a>
                                            <br />
                                        </>
                                    )}

                                    {socialMediaUrls?.twitter && (
                                        <>
                                            <a
                                                href={socialMediaUrls?.twitter}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Twitter
                                            </a>
                                            <br />
                                        </>
                                    )}

                                    {socialMediaUrls?.instagram && (
                                        <>
                                            <a
                                                href={socialMediaUrls?.instagram}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Instagram
                                            </a>
                                            <br />
                                        </>
                                    )}

                                    {socialMediaUrls?.linkedin && (
                                        <a
                                            href={socialMediaUrls?.linkedin}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Linkedin
                                        </a>
                                    )}
                                </p>
                            </div>
                        </div>

                        <hr />

                        <h6 className="text-lg my-4">Additional Information (Optional)</h6>
                        <div className="flex justify-between">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    How Frequently do you create content?
                                </span>
                                <p className="font-semibold">{contentCreationFrequency}</p>
                            </div>

                            <div className="flex flex-1 text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    <div className="block text-base mr-5">
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Where did you hear about us?
                                        </span>
                                        <p className="font-semibold">{whereDidYouHearAboutUs}</p>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default BrandApplicationTab;
