import HOC from "../../shared/HOC";
import ContentTable from "./Table";

const ContentApplicationsTab = () => {
  return (
    <HOC route="content" title="Content List">
      <ContentTable />
    </HOC>
  );
};

export default ContentApplicationsTab;
