import { useEffect, useState } from "react";

interface Props {
    setStep: Function;
    formData: any;
    setForm: Function;
}

const RecoveryPhrase = ({ setStep, formData, setForm }: Props) => {
    const [error, setError] = useState("");
    const [valid, setValid] = useState(false);

    const { email, recoveryPhrase } = formData;

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setError("");
        setForm((prevState: any) => {
            return { ...prevState, [name]: value };
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setStep(2);
    };

    useEffect(() => {
        if (email.length > 5 && recoveryPhrase.length > 30) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [email, recoveryPhrase]);
    return (
        <div className="w-full">
            <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                Password Recovery
            </h1>
            <form onSubmit={handleSubmit}>
                <label className="block text-sm">
                    <span className="text-gray-700 dark:text-gray-400 font-medium">Email</span>
                    <input
                        className="block p-2 rounded-lg w-full mt-1 text-sm border dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                        name="email"
                        value={email}
                        type="email"
                        onChange={handleChange}
                    />
                </label>
                <label className="block mt-4 text-sm">
                    <span className="text-gray-700 dark:text-gray-400 font-medium">
                        Recovery Phrase
                    </span>
                    <textarea
                        name="recoveryPhrase"
                        className="block p-2 resize-none h-36 rounded-lg border w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                        value={recoveryPhrase}
                        onChange={handleChange}
                    ></textarea>
                </label>
                {error ? <div className="text-red-600 text-sm">{error}</div> : null}
                <button
                    type="submit"
                    disabled={!valid}
                    className={
                        valid
                            ? "block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                            : "block w-full opacity-50 px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                    }
                >
                    Next
                </button>
            </form>
        </div>
    );
};

export default RecoveryPhrase;
