import BarChart from "./BarChart";
import LineChart from "./LineChart";
import PieChart from "./PieChart";

/**
 *
 * Right side GUI for dashboard
 */
const Info = () => {
    return (
        <div>
            <div className="grid gap-6 mb-8 md:grid-cols-2 2xl:grid-cols-3">
                {/* <!-- Doughnut/Pie chart --> */}
                <PieChart />
                {/* <!-- Lines chart --> */}
                <LineChart />
                {/* <!-- Bars chart --> */}
                <BarChart />
            </div>
        </div>
    );
};

export default Info;
