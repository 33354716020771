import { Link } from "react-router-dom";

interface Props {
  recoveryPhrase: string;
}
const RecoveryPhrase = (props: Props) => {
  const { recoveryPhrase } = props;
  const copyKey = () => {
    /* Get the text field */
    let copyText: any = document.getElementById("recovery");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");
  };
  let saveFile = () => {
    // This variable stores all the data.
    let data = recoveryPhrase;

    // Convert the text to BLOB.
    const textToBLOB = new Blob([data], { type: "text/plain" });
    const sFileName = "recovery.txt"; // The file to save the data.

    let newLink = document.createElement("a");
    newLink.download = sFileName;

    if (window.webkitURL != null) {
      newLink.href = window.webkitURL.createObjectURL(textToBLOB);
    } else {
      newLink.href = window.URL.createObjectURL(textToBLOB);
      newLink.style.display = "none";
      document.body.appendChild(newLink);
    }

    newLink.click();
  };
  return (
    <div className="flex-1">
      <h1 className="mb-4 text-center text-xl font-semibold text-gray-700 dark:text-gray-200">
        Your account was created successfully.
      </h1>
      <div className="mb-2">
        Please store this recovery phrase for your account safely
      </div>
      <label className="font-medium" htmlFor="recovery">
        Recovery Phrase:
      </label>
      <textarea
        className="p-2 rounded block w-full mt-1 border border-gray-400 resize-none"
        id="recovery"
        value={recoveryPhrase}
        readOnly
      />
      <div className="flex justify-end mt-2">
        <button
          onClick={() => copyKey()}
          className="text-blue-600 pr-1 border-gray-400 border-r"
        >
          Copy
        </button>
        <button onClick={() => saveFile()} className="text-blue-600 pl-1">
          Download
        </button>
      </div>
      <div className="mt-2">
        <Link to="/login">
          <button className="w-full px-4 py-2 mt-4 text-base font-medium leading-5 text-center text-white bg-green-600 border border-transparent rounded-lg">
            Finish
          </button>
        </Link>
      </div>
    </div>
  );
};
export default RecoveryPhrase;
