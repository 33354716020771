import HOC from "../../shared/HOC";
import BrandApplicationTable from "./Table";

const BrandTab = () => {
  return (
    <HOC route="brand" title="Brands">
      <BrandApplicationTable />
    </HOC>
  );
};

export default BrandTab;
