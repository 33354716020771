import gql from "graphql-tag";

export const UpdateNFTStatus = gql`
    mutation ($nftId: String!, $status: String!, $reason: String!, $password: String!) {
        updateNftStatus(nftId: $nftId, status: $status, reason: $reason, password: $password) {
            ok
            error {
                name
                message
                statusCode
            }
        }
    }
`;

export const UpdateFeaturedTag = gql`
    mutation ($nftId: String!, $featured: Boolean!) {
        updateNftFeaturedTag(nftId: $nftId, featured: $featured) {
            ok
            error {
                name
                message
                statusCode
            }
        }
    }
`;

// Query to get market cap
export const nftMarketCap = gql`
    query ($title: String!, $description: String!) {
        getEstimatedMarketCAPOfNft(title: $title, description: $description) {
            ok
            error {
                name
                message
                statusCode
            }
            estimatedMarketCAP
        }
    }
`;
