import { useRef, useState } from "react";
import { useOutsideAlerter } from "src/utils/outsideAlerter";
import StatusChangeModal from "./statusModal";

interface Member {
    firstName: string;
    lastName: string;
    role: string;
    createdAt: string;
    active: boolean;
    employeeId: string;
    email: string;
}

interface Props {
    member: Member;
}

const TableItem = ({ member }: Props) => {
    const [optionsDD, setOptionsDD] = useState(false);
    const [statusModal, setStatus] = useState(false);

    // Function to convert date from numbers
    const getDate = (date: any) => {
        let _date: any;
        _date = new Date(date * 1).toLocaleDateString();
        return _date;
    };
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setOptionsDD);
    return (
        <>
            {statusModal && <StatusChangeModal member={member} setShow={setStatus} />}
            <tr className="text-gray-700 dark:text-gray-400 cursor-pointer">
                <td className="px-4 py-3">
                    <div className="flex items-center text-sm">
                        {/* <!-- Avatar with inset shadow --> */}

                        <div>
                            <p className="font-semibold">
                                {member.firstName} {member.lastName}
                            </p>
                        </div>
                    </div>
                </td>
                <td className="px-4 py-3 text-xs">
                    <span
                        className={
                            member.active
                                ? "px-2 py-1 capitalize font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100"
                                : "px-2 py-1 capitalize font-semibold leading-tight text-red-700 bg-red-100 rounded-full dark:text-white dark:bg-red-600"
                        }
                    >
                        {member.active ? "Active" : "Inactive"}
                    </span>
                </td>
                <td className="px-4 py-3 text-xs">
                    <span className={"py-1 capitalize font-semibold leading-tight"}>
                        {member.role}
                    </span>
                </td>
                <td className="px-4 py-3 text-sm">{getDate(member.createdAt)}</td>
                <td>
                    <div className="relative px-4 py-3" ref={wrapperRef}>
                        <button
                            onClick={() => {
                                setOptionsDD(!optionsDD);
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-three-dots-vertical transform rotate-90"
                                viewBox="0 0 16 16"
                            >
                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                            </svg>
                        </button>
                        {optionsDD && (
                            <div className="absolute bg-white border rounded-lg z-10 py-2 left-2 text-sm font-medium">
                                <div
                                    onClick={() => {
                                        setStatus(true);
                                    }}
                                    className="py-2 px-4 hover:bg-purple-300"
                                >
                                    {member.active ? "Disable" : "Enable"}
                                </div>

                                <div className="py-2 px-4 hover:bg-purple-300">Change Role</div>
                            </div>
                        )}
                    </div>
                </td>
            </tr>
        </>
    );
};
export default TableItem;
