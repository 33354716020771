import React from "react";
import Sidebar from "../admin/Sidebar";

interface Props {
  children: any;
  route: string;
  title?: string;
}

const HOC = ({ children, route, title = "Dashboard" }: Props) => {
  return (
    <div className="flex flex-row flex-1 w-full h-full">
      <Sidebar route={route} />
      <div className="flex flex-col pt-5 w-full flex-1 bg-gray-50">
        <div className="flex items-center justify-between mx-6">
          <h1 className="p-2 text-xl font-semibold">{title}</h1>
        </div>
        <main className="h-full w-full overflow-y-auto container">
          <div className="container px-6 min-h-full mx-auto grid">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default HOC;
