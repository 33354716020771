import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useApolloClient } from "@apollo/client";
import { GetUserData } from "src/graphql/queries/auth";
import { setUserData } from "src/redux/actions/action";

const AuthWrapper = ({ children }: any) => {
    const client = useApolloClient();
    const dispatch = useDispatch();

    const getUserQuery = async () => {
        try {
            const res = await client.query({
                query: GetUserData,
                fetchPolicy: "network-only",
            });
            const { getEmployeeDetails } = res.data;
            if (getEmployeeDetails.ok) {
                dispatch(
                    setUserData({
                        email: getEmployeeDetails.employee.email,
                        firstName: getEmployeeDetails.employee.firstName,
                        lastName: getEmployeeDetails.employee.lastName,
                        role: getEmployeeDetails.employee.role,
                    })
                );
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            getUserQuery();
        }
    }, []);

    return <div className="h-full w-full">{children}</div>;
};
export default AuthWrapper;
