const TrashCan = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2 4H3.33333H14"
            stroke="black"
            strokeOpacity="0.87"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.33337 3.9987V2.66536C5.33337 2.31174 5.47385 1.9726 5.7239 1.72256C5.97395 1.47251 6.31309 1.33203 6.66671 1.33203H9.33337C9.687 1.33203 10.0261 1.47251 10.2762 1.72256C10.5262 1.9726 10.6667 2.31174 10.6667 2.66536V3.9987M12.6667 3.9987V13.332C12.6667 13.6857 12.5262 14.0248 12.2762 14.2748C12.0261 14.5249 11.687 14.6654 11.3334 14.6654H4.66671C4.31309 14.6654 3.97395 14.5249 3.7239 14.2748C3.47385 14.0248 3.33337 13.6857 3.33337 13.332V3.9987H12.6667Z"
            stroke="black"
            strokeOpacity="0.87"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default TrashCan;
