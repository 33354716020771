import ReactECharts from "echarts-for-react";

const BarChart = () => {
    const options = {
        xAxis: {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
            type: "value",
        },
        series: [
            {
                data: [120, 200, 150, 80, 70, 110, 130],
                type: "bar",
                showBackground: true,
                backgroundStyle: {
                    color: "rgba(180, 180, 180, 0.2)",
                },
            },
        ],
    };

    if (!options) return <div> Loading....</div>;

    return (
        <div className="min-w-0 p-4 bg-white rounded-lg shadow flex flex-col items-center">
            <div className="flex justify-items-start w-full">
                <h4 className="mb-1 font-semibold text-gray-800">Bar Chart</h4>
            </div>
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        display: " block",
                        width: "397px",
                        height: "280px",
                    }}
                />
            )}
        </div>
    );
};

export default BarChart;
