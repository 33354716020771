import gql from "graphql-tag";

export const Signin = gql`
    mutation ($email: String!, $password: String!) {
        employeeSignIn(email: $email, password: $password) {
            ok
            error {
                name
                message
                statusCode
            }
            authToken
            refreshToken
        }
    }
`;
export const SendInvite = gql`
    mutation ($email: String!, $password: String!, $role: String!) {
        sendInvite(email: $email, password: $password, role: $role) {
            ok
            error {
                name
                message
                statusCode
            }
        }
    }
`;
export const AcceptInvite = gql`
    mutation ($inviteToken: String!, $password: String!, $firstName: String!, $lastName: String!) {
        acceptInvite(
            inviteToken: $inviteToken
            password: $password
            firstName: $firstName
            lastName: $lastName
        ) {
            ok
            error {
                name
                message
                statusCode
            }
            recoveryPhrase
        }
    }
`;
export const ChangePassword = gql`
    mutation ($currentPassword: String!, $newPassword: String!) {
        employeeChangePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
            ok
            error {
                name
                message
                statusCode
            }
        }
    }
`;
export const DeleteEmployee = gql`
    mutation ($employeeEmail: String!) {
        deleteEmployee(employeeEmail: $employeeEmail) {
            ok
            error {
                name
                message
                statusCode
            }
        }
    }
`;
export const RefreshToken = gql`
    mutation ($refreshToken: String!) {
        refreshEmployeeAuthToken(refreshToken: $refreshToken) {
            ok
            error {
                name
                message
                statusCode
            }
            authToken
            refreshToken
        }
    }
`;
export const DeleteInvite = gql`
    mutation ($inviteId: String, $email: String) {
        deleteInvite(inviteId: $inviteId, email: $email) {
            ok
            error {
                name
                message
                statusCode
            }
        }
    }
`;
export const ChangeEmpActiveStatus = gql`
    mutation ($employeeId: String, $email: String, $active: Boolean!) {
        updateEmployeeActiveStatus(employeeId: $employeeId, email: $email, active: $active) {
            ok
            error {
                name
                message
                statusCode
            }
        }
    }
`;

export const RecoverPassword = gql`
    mutation ($email: String!, $recoveryPhrase: String!, $newPassword: String!) {
        employeeRecoverPassword(
            email: $email
            recoveryPhrase: $recoveryPhrase
            newPassword: $newPassword
        ) {
            ok
            error {
                name
                message
                statusCode
            }
        }
    }
`;
