import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RecoverPassword } from "src/graphql/mutation/auth";
import NewPasswordField from "../settings/changePassword/newPassword";

interface Props {
    emailForm: any;
    setStep: Function;
}

const ResetPassword = ({ emailForm, setStep }: Props) => {
    const [formData, setForm] = useState({ newPassword: "", confirmPassword: "" });
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [confirmError, setConfirmError] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const { newPassword, confirmPassword } = formData;

    const RecoverMutation = useMutation(RecoverPassword)[0];
    const Recover = async () => {
        try {
            const res = await RecoverMutation({
                variables: {
                    email: emailForm.email.trim(),
                    recoveryPhrase: emailForm.recoveryPhrase.trim(),
                    newPassword: newPassword,
                },
            });
            const { employeeRecoverPassword } = res.data;
            const { ok, error } = employeeRecoverPassword;
            if (ok) {
                setSuccess(true);
            } else {
                setError(error?.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        Recover();
    };
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setError("");
        setForm((prevState) => {
            return { ...prevState, [name]: value };
        });
    };

    useEffect(() => {
        if (confirmPassword.length >= newPassword.length) {
            if (newPassword !== confirmPassword) {
                setConfirmError(true);
            } else {
                setConfirmError(false);
            }
        } else if (confirmPassword !== "") {
            setConfirmError(true);
        }
    }, [newPassword, confirmPassword]);

    useEffect(() => {
        if (
            /\W|_/.test(newPassword) &&
            /[A-Z]/.test(newPassword) &&
            /[a-z]/.test(newPassword) &&
            /\d/.test(newPassword) &&
            newPassword.length > 7 &&
            !error &&
            !confirmError &&
            confirmPassword.length > 0
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [newPassword, error, confirmError]);

    return (
        <div className="w-full">
            <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                Password Recovery
            </h1>
            <form onSubmit={handleSubmit}>
                <NewPasswordField handleChange={handleChange} newPassword={newPassword} />
                <label className="block mt-4 text-sm">
                    <span className="text-gray-700 dark:text-gray-400 font-medium">
                        Confirm Password
                    </span>
                    <input
                        className="block p-2 rounded-lg border w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleChange}
                    />
                </label>
                {error ? (
                    <div className="font-medium text-sm flex gap-4 my-2">
                        <div className="text-red-600">{error}</div>
                        <button
                            type="button"
                            onClick={() => {
                                setStep(1);
                            }}
                            className="text-purple-600 font-medium"
                        >
                            Go back
                        </button>
                    </div>
                ) : null}
                {confirmError && (
                    <div className="text-sm font-medium text-red-500">Passwords don't match</div>
                )}
                {success ? (
                    <div className="flex gap-4">
                        <div className="text-sm text-green-500 font-medium">Reset Successful.</div>
                        <div className="font-medium text-sm text-purple-600">
                            <Link to="/login">Login</Link>
                        </div>
                    </div>
                ) : (
                    <button
                        type="submit"
                        disabled={disabled}
                        className={
                            disabled
                                ? "block w-full opacity-50 px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                                : "block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                        }
                    >
                        Reset Password
                    </button>
                )}
            </form>
        </div>
    );
};
export default ResetPassword;
