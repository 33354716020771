import { useState } from "react";
import TrashCan from "../shared/icons/trash";
import DeleteInviteModal from "./deleteModal";

interface Data {
    email: string;
    role: string;
}
interface Member {
    data: Data;
    role: string;
    createdAt: string;
    voteId: string;
    status: string;
}

interface Props {
    member: Member;
}

const TableItem = ({ member }: Props) => {
    const [deleteModal, setModal] = useState(false);

    // Function to convert date from numbers
    const getDate = (date: any) => {
        let _date: any;
        _date = new Date(date * 1).toLocaleDateString();
        return _date;
    };

    return (
        <>
            {deleteModal && (
                <DeleteInviteModal
                    setShow={setModal}
                    email={member.data.email}
                    inviteId={member.voteId}
                />
            )}
            <tr className="text-gray-700 dark:text-gray-400 cursor-pointer">
                <td className="px-4 py-3">
                    <div className="flex items-center text-sm">
                        <div>
                            <p className="font-semibold">{member.data.email}</p>
                        </div>
                    </div>
                </td>
                <td className="px-4 py-3 text-xs">
                    <span
                        className={
                            member.role === "super" || member.role === "admin"
                                ? "pr-2 py-1 capitalize font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100"
                                : "pr-2 py-1 capitalize font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full dark:text-white dark:bg-orange-600"
                        }
                    >
                        {member.data.role}
                    </span>
                </td>
                <td className="px-4 py-3 text-sm">
                    {member.status === "votingInProgress"
                        ? "Voting in progress"
                        : member.status === "majorityAccepted"
                        ? "Majority accepted"
                        : member.status === "majorityRejected"
                        ? "Majority rejected"
                        : member.status === "open"
                        ? "Open"
                        : member.status === "closed"
                        ? "Closed"
                        : null}
                </td>
                <td className="px-4 py-3 text-sm">{getDate(member.createdAt)}</td>
                <td>
                    <div className="relative px-6 py-3">
                        <button
                            disabled={member?.status !== "open"}
                            className={member.status !== "open" ? "opacity-50" : ""}
                            onClick={() => {
                                setModal(true);
                            }}
                        >
                            <TrashCan />
                        </button>
                    </div>
                </td>
            </tr>
        </>
    );
};
export default TableItem;
