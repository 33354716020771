import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { UpdateFeaturedTag, nftMarketCap } from "src/graphql/mutation/content";
// import { GetNftList } from "src/graphql/queries/list";
import Sidebar from "../../admin/Sidebar";
import AcceptModal from "./acceptModal";
import PrivateModal from "./makePrivate";
import RejectModal from "./rejectModal";

const ContentApplication = () => {
    const location = useLocation() as any;
    const { nft } = location.state;
    const [acceptModal, setAccept] = useState(false);
    const [rejectModal, setReject] = useState(false);
    const [privateModal, setPrivate] = useState(false);

    const FeaturedMutation = useMutation(UpdateFeaturedTag)[0];
    const history = useHistory();

    const { data, loading } = useQuery(nftMarketCap, {
        variables: { title: nft.title, description: nft.description },
    });

    const { estimatedMarketCAP } = data?.getEstimatedMarketCAPOfNft
        ? data?.getEstimatedMarketCAPOfNft
        : 0;

    const toggleFeatured = async () => {
        try {
            const res = await FeaturedMutation({
                variables: { nftId: nft?.nftId, featured: !nft?.featured },
            });
            const { updateNftFeaturedTag } = res.data;
            const { ok } = updateNftFeaturedTag;
            if (ok) {
                history.push("/content");
            }
        } catch (err) {
            console.log(err);
        }
    };

    // Function to get market cap
    //   const getMarketCap = async () => {
    //     try {
    //         const res = await FeaturedMutation({
    //             variables: { nftId: nft?.nftId, featured: !nft?.featured },
    //         });
    //         const { updateNftFeaturedTag } = res.data;
    //         const { ok } = updateNftFeaturedTag;
    //         if (ok) {
    //             history.push("/content");
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    //   const getMarketCap = async () => {
    //     try {
    //         const res = await client.query({
    //             query: nftMarketCap,
    //             variables: {
    //                 title: formData?.title,
    //                 description: formData?.description,
    //             },
    //         });
    //         const { getEstimatedMarketCAPOfNft } = res.data;
    //         const { ok, error, estimatedMarketCAP } = getEstimatedMarketCAPOfNft;
    //         if (ok) {
    //             setMarketCap(estimatedMarketCAP);
    //             setMarketValue(estimatedMarketCAP);
    //             setData((prevState) => {
    //                 return { ...prevState, minInitialCAP: estimatedMarketCAP };
    //             });
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    if (loading) {
        return <div>loading...</div>;
    }

    return (
        <div className="flex flex-row flex-1 w-full h-full capitalize">
            {acceptModal && <AcceptModal setModal={setAccept} id={nft.nftId} />}
            {rejectModal && <RejectModal setModal={setReject} id={nft.nftId} />}
            {privateModal && <PrivateModal setModal={setPrivate} id={nft.nftId} />}
            <Sidebar route="content" />
            <div className="flex flex-col pt-5 w-full flex-1 bg-gray-50">
                <div className="flex items-center justify-between mx-6">
                    <h1 className="p-2 text-xl font-semibold">
                        {" "}
                        <span className="font-normal">Creator : </span> {nft.brand.brandName}
                    </h1>
                    {nft.status === "submitted" ? (
                        <div>
                            <button
                                onClick={() => {
                                    setAccept(true);
                                }}
                                className="p-2 px-4 bg-green-700 text-white rounded-md mr-2 cursor-pointer"
                            >
                                Approve
                            </button>
                            <button
                                onClick={() => {
                                    setReject(true);
                                }}
                                className="p-2 px-4 bg-red-700 text-white rounded-md cursor-pointer"
                            >
                                Reject
                            </button>
                        </div>
                    ) : (
                        <div>{nft.status}</div>
                    )}
                </div>
                <main className="h-full overflow-y-auto container pb-16">
                    <div className="container px-8 mx-auto grid">
                        <hr />
                        <div className="flex justify-center my-4 h-96">
                            <img src={nft.thumbnailUrl} className="h-full" alt="thumbnail" />
                        </div>
                        <h6 className="text-lg my-4">Content Information</h6>
                        <div className="flex  mb-8">
                            <div className="flex flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">Title :</span>
                                <p className="font-semibold">{nft.title}</p>
                            </div>
                        </div>
                        <div className="flex mb-8">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Description :
                                </span>
                                <p className="font-semibold">{nft.description}</p>
                            </div>
                        </div>

                        <div className="flex mb-8">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">Category :</span>
                                <p className="font-semibold">{nft.category}</p>
                            </div>
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Sub Category :
                                </span>
                                <p className="font-semibold"> {nft.subCategory} </p>
                            </div>
                        </div>
                        <div className="flex mb-8">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Media Links
                                </span>
                                {nft.assets.map((item: any, i: number) => (
                                    <a
                                        href={item.url}
                                        target="_blank"
                                        key={i}
                                        className="text-blue-400"
                                        rel="noreferrer"
                                    >
                                        {item.type}
                                    </a>
                                ))}
                            </div>

                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">Tags :</span>
                                <p className="font-semibold">{nft.tags.join(", ")}</p>
                            </div>
                        </div>
                        {nft.status === "approved" && (
                            <div className="mb-8 flex justify-between">
                                <div className="flex-1">
                                    <div className="font-semibold">Take down content</div>
                                    <button
                                        onClick={() => {
                                            setPrivate(!privateModal);
                                        }}
                                        className="mt-4 bg-red-700 font-semibold px-4 py-2 rounded-lg text-white"
                                    >
                                        Make Private
                                    </button>
                                </div>
                                <div className="flex-1">
                                    <div className="font-semibold">
                                        {nft?.featured
                                            ? "Remove from featred articles"
                                            : "Add to featured articles"}
                                    </div>
                                    <button
                                        onClick={() => {
                                            toggleFeatured();
                                        }}
                                        className={
                                            nft?.featured
                                                ? "mt-4 bg-red-700 font-semibold px-4 py-2 rounded-lg text-white"
                                                : "mt-4 bg-green-700 font-semibold px-4 py-2 rounded-lg text-white"
                                        }
                                    >
                                        {nft?.featured ? "Remove" : "Add"}
                                    </button>
                                </div>
                            </div>
                        )}

                        <hr />

                        <h6 className="text-lg my-4">Revenue Information</h6>
                        <div className="flex justify-between mb-8">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Valuation :
                                </span>
                                <p className="font-semibold">Q {estimatedMarketCAP || "0"}</p>
                            </div>

                            <div className="flex flex-1 text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    <div className="block text-base mr-5">
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Brand's Revenue Share :
                                        </span>
                                        <p className="font-semibold">{`${nft.cohortPercentage}%`}</p>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <div className="flex flex-1 flex-col text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    Ownership Type :
                                </span>
                                <p className="font-semibold">{nft.ownership}</p>
                            </div>

                            <div className="flex flex-1 text-base mr-5">
                                <span className="text-gray-700 dark:text-gray-300">
                                    <div className="block text-base mr-5">
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Maximum Cohorts :
                                        </span>
                                        <p className="font-semibold">
                                            {nft.maxCohorts ? nft.maxCohorts : "N/A"}
                                        </p>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default ContentApplication;
