import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { ChangePassword } from "src/graphql/mutation/auth";
import NewPasswordField from "./newPassword";

const ChangePasswordForm = () => {
    const [formData, setForm] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [reqError, setReqError] = useState<any>("");
    const [success, setSuccess] = useState(false);

    const { newPassword, currentPassword, confirmPassword } = formData;

    const ChangeMutation = useMutation(ChangePassword)[0];
    const Change = async () => {
        try {
            const res = await ChangeMutation({ variables: { newPassword, currentPassword } });
            const { employeeChangePassword } = res.data;
            const { ok, error } = employeeChangePassword;
            if (ok) {
                setForm({
                    currentPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                });
                setSuccess(true);
            } else {
                setReqError(error?.message);
            }
        } catch (err: any) {
            setReqError(err?.message);
        }
    };

    const handleChange = (e: any) => {
        setReqError("");
        setSuccess(false);

        const { name, value } = e.target;
        setForm((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        Change();
    };

    useEffect(() => {
        if (confirmPassword.length >= newPassword.length) {
            if (newPassword !== confirmPassword) {
                setError(true);
            } else {
                setError(false);
            }
        } else if (confirmPassword !== "") {
            setError(true);
        }
    }, [newPassword, confirmPassword]);

    useEffect(() => {
        if (
            /\W|_/.test(newPassword) &&
            /[A-Z]/.test(newPassword) &&
            /[a-z]/.test(newPassword) &&
            /\d/.test(newPassword) &&
            newPassword.length > 7 &&
            currentPassword.length > 7 &&
            !error
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [newPassword, currentPassword, error]);

    return (
        <div className="p-4 w-96">
            <div className="text-xl font-bold">Change Password</div>
            <form onSubmit={handleSubmit} className="p-2">
                <div className="mb-4">
                    <label
                        htmlFor="currentPassword"
                        className="text-gray-700 dark:text-gray-400 text-sm font-medium"
                    >
                        Current Password
                    </label>
                    <input
                        type="password"
                        id="currentPassword"
                        className="block rounded-lg p-2 w-full mt-1 text-sm border dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                        name="currentPassword"
                        value={currentPassword}
                        onChange={handleChange}
                        minLength={8}
                        maxLength={30}
                    />
                </div>
                <NewPasswordField newPassword={newPassword} handleChange={handleChange} />
                <div className="mb-4">
                    <label
                        htmlFor="confirmPassword"
                        className="text-gray-700 dark:text-gray-400 text-sm font-medium"
                    >
                        Confirm Password
                    </label>
                    <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleChange}
                        className="block rounded-lg p-2 w-full mt-1 text-sm border dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                        minLength={8}
                        maxLength={30}
                    />
                </div>
                {error && (
                    <div className="text-sm font-medium text-red-500">Passwords don't match</div>
                )}
                {reqError && <div className="text-sm font-medium text-red-500">{reqError}</div>}
                {success && (
                    <div className="text-sm font-medium text-green-500">Updated Successfully</div>
                )}
                <button
                    className={
                        disabled
                            ? "opacity-50 block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                            : "block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                    }
                    type="submit"
                    disabled={disabled}
                >
                    Change Password
                </button>
            </form>
        </div>
    );
};

export default ChangePasswordForm;
