import { ApolloClient, ApolloLink, concat, createHttpLink, InMemoryCache } from "@apollo/client";

// Create customFetch function for handling re-authorization
// This customFetch (or any fetch you pass to the link) gets uri and options as arguments. We'll use those when we actually execute a fetch.
const customFetch = async (uri, options) => {
    // No custom logic for SSR
    if (typeof window === "undefined") {
        return await fetch(uri, options);
    }

    // send normal request
    const req = await fetch(uri, options);
    const json = await req.json();

    // check for unauthorized error
    const isUnauthorized = json.errors?.some((e) => e.extensions?.code === "UNAUTHENTICATED");

    // request completed as normal
    if (!isUnauthorized) {
        return {
            json: () => new Promise((resolve) => resolve(json)),
            text: () => new Promise((resolve) => resolve(JSON.stringify(json))),
            ok: true,
        };
    }

    // `accessToken` is expired, if this executes
    // get a new token
    const refreshToken = localStorage.getItem("refreshToken");
    const accessToken = localStorage.getItem("accessToken");
    const refreshQuery = {
        variables: { refreshToken },
        query: `mutation ($refreshToken: String!) {
                    refreshEmployeeAuthToken(refreshToken: $refreshToken) {
                        ok
                        error {
                            name
                            message
                            statusCode
                        }
                        authToken
                        refreshToken
                    }
                }`,
    };
    const refreshOptions = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            accept: "*/*",
            authorization: accessToken,
        },
        body: JSON.stringify(refreshQuery),
    };
    const refreshResp = await fetch(uri, refreshOptions);
    const rjson = await refreshResp.json();

    // validate and set new token
    if (rjson?.data?.refreshEmployeeAuthToken?.ok) {
        const res = rjson.data.refreshEmployeeAuthToken;
        localStorage.setItem("accessToken", res.authToken);
        localStorage.setItem("refreshToken", res.refreshToken);
        // repeat the original request with new token
        options.headers.authorization = `Bearer ${res.authToken}`;
        return await fetch(uri, options);
    } else {
        // Failed to renew accessToken
        // logout the user
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");

        // return original failed response
        return {
            json: () => new Promise((resolve) => resolve(json)),
            text: () => new Promise((resolve) => resolve(JSON.stringify(json))),
            ok: true,
        };
    }
};

let token;

const authMiddleware = new ApolloLink((operation, forward) => {
    // get the authentication token from local storage if it exists
    if (typeof window !== "undefined") {
        token = localStorage.getItem("accessToken");
    }

    if (token) {
        operation.setContext({
            headers: {
                authorization: `Bearer ${token}`,
            },
        });
    }
    return forward(operation);
});

const API_URL = process.env.REACT_APP_API_URI + "/graphql";

const httpLink = createHttpLink({
    uri: API_URL,
    credentials: "same-origin",
    fetch: customFetch,
});
const client = new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache: new InMemoryCache(),
});
export default client;
