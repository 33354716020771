import { useState } from "react";
import { useMutation } from "@apollo/client";
import { ChangeEmpActiveStatus } from "src/graphql/mutation/auth";
import Cross from "../shared/icons/cross";

interface Member {
    email: string;
    role: string;
    firstName: string;
    lastName: string;
    employeeId: string;
    active: boolean;
}

interface Props {
    setShow: Function;
    member: Member;
}

const StatusChangeModal = (props: Props) => {
    const { setShow, member } = props;
    const { email, employeeId, active, firstName, lastName, role } = member;

    const [error, setError] = useState<any>(null);

    const UpdateMutation = useMutation(ChangeEmpActiveStatus)[0];
    const toggleStatus = async () => {
        try {
            const res = await UpdateMutation({
                variables: { employeeId: employeeId, email: email, active: !active },
            });

            const { updateEmployeeActiveStatus } = res.data;
            const { ok, error } = updateEmployeeActiveStatus;
            if (ok) {
                setShow(false);
                window.location.reload();
            } else {
                setError(error?.message);
            }
        } catch (err: any) {
            setError(err?.message);
        }
    };

    const handleSubmit = () => {
        toggleStatus();
    };

    return (
        <div className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
            <div
                className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:w-96"
                role="dialog"
                id="modal"
            >
                <header className="flex justify-end">
                    <button
                        className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                        aria-label="close"
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        <Cross />
                    </button>
                </header>

                <div className="mt-4 mb-6">
                    <p className="mb-2 text-center text-lg font-semibold text-gray-700 dark:text-gray-300">
                        {active ? "Disable User" : "Enable User"}
                    </p>
                    <p className="text-center">
                        Are you sure you want to {active ? "disable" : "enable"} this user ?
                    </p>
                    <div className="flex justify-center my-3">
                        <div>
                            <div>Email: {email}</div>
                            <div>Name: {`${firstName} ${lastName}`}</div>
                            <div>Role: {role}</div>
                        </div>
                    </div>
                    {error ? <div className="text-sm text-red-600 mt-2">{error}</div> : null}
                    <div className="flex">
                        <button
                            type="submit"
                            onClick={() => {
                                handleSubmit();
                            }}
                            className={
                                active
                                    ? "block w-full px-4 py-2 mt-4 text-base font-medium leading-5 text-center text-white bg-red-600 border border-transparent rounded-lg  hover:bg-red-700"
                                    : "block w-full px-4 py-2 mt-4 text-base font-medium leading-5 text-center text-white bg-green-600 border border-transparent rounded-lg  hover:bg-golden-50"
                            }
                        >
                            {active ? "Disable User" : "Enable User"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default StatusChangeModal;
