import { useApolloClient } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { GetBrandApplications } from "src/graphql/queries/list";
import { useOutsideAlerter } from "src/utils/outsideAlerter";
import ArrowHeadDown from "../shared/icons/arrowHeadDown";
/**
 *
 * Brand Table list
 */

const BrandApplicationTable = () => {
    const history = useHistory();

    const handleClick = (id: string) => {
        history.push(`/brand-application/${id}`);
    };
    const [statusFilter, setFilter] = useState("All");
    const [statusDD, setStatusDD] = useState(false);
    const statusList = ["All", "Pending", "Approved", "Rejected", "Closed"];
    const [page, setPage] = useState(1);
    const [brandList, setBrand] = useState([]) as any;

    const statusRef = useRef(null);

    useOutsideAlerter(statusRef, setStatusDD);

    const client = useApolloClient();
    const getData = async () => {
        try {
            const res = await client.query({
                query: GetBrandApplications,
                variables: {
                    status: statusFilter === "All" ? null : statusFilter.toLowerCase(),
                    page: page,
                    limit: 20,
                },
                fetchPolicy: "network-only",
            });

            const { getBrandApplications } = res.data;
            const { brandApplications } = getBrandApplications;
            setBrand(brandApplications);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        getData();
    }, [statusFilter, page]);

    return (
        <div className="w-full flex flex-col overflow-hidden rounded-lg shadow-xs">
            <div className="w-full flex-1 overflow-x-auto">
                <div className="flex justify-end px-4">
                    <div
                        onClick={() => setStatusDD(!statusDD)}
                        ref={statusRef}
                        className="relative flex border border-gray-300 rounded-full px-3 py-2 cursor-pointer"
                    >
                        Status : {statusFilter}
                        <span className="ml-2">
                            <ArrowHeadDown />
                        </span>
                        {statusDD && (
                            <div className="absolute bg-white w-full rounded-lg border border-gray-300 left-0 top-11">
                                {statusList.map((item) => (
                                    <div
                                        key={item}
                                        onClick={() => {
                                            setFilter(item);
                                            setStatusDD(false);
                                        }}
                                        className="px-4 py-2 hover:bg-purple-300"
                                    >
                                        {item}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <table className="w-full whitespace-no-wrap">
                    <thead>
                        <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                            <th className="px-4 py-3">Brands</th>
                            <th className="px-4 py-3">Brand Type</th>
                            <th className="px-4 py-3">Status</th>
                            <th className="px-4 py-3">Date</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                        {brandList?.length > 0 &&
                            brandList.map((item: any, index: number) => (
                                <tr
                                    onClick={() => {
                                        handleClick(item.brandApplicationId);
                                    }}
                                    key={index}
                                    className="text-gray-700 dark:text-gray-400 cursor-pointer"
                                >
                                    <td className="px-4 py-3">
                                        <div className="flex items-center text-sm">
                                            {/* <!-- Avatar with inset shadow --> */}

                                            <div>
                                                <p className="font-semibold">{item.brandName}</p>
                                                {/* <p className="text-xs text-gray-600 dark:text-gray-400">
                                            Unemployed
                                        </p> */}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 text-xs font-medium capitalize">
                                        {item.type}
                                    </td>
                                    <td className="px-4 py-3 text-xs capitalize">
                                        {item.status === "approved" || item.status === "closed" ? (
                                            <span className="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100">
                                                {item.status}
                                            </span>
                                        ) : item.status === "pending" ? (
                                            <span className="px-2 py-1 font-semibold leading-tight text-yellow-700 bg-yellow-100 rounded-full dark:text-white dark:bg-yellow-600">
                                                {item.status}
                                            </span>
                                        ) : (
                                            <span className="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-full dark:text-red-100 dark:bg-red-700">
                                                {item.status}
                                            </span>
                                        )}
                                    </td>
                                    <td className="px-4 py-3 text-sm">
                                        {new Date(item.createdAt * 1).toLocaleDateString()}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            <div className="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
                <span className="flex items-center col-span-3">
                    Showing {`${20 * (page - 1) + 1} to ${page * 20}`}
                </span>
                <span className="col-span-1"></span>

                <span className="flex col-span-4 mt-2">
                    <nav aria-label="Table navigation">
                        <ul className="inline-flex items-center">
                            <li>
                                <button
                                    className="px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple"
                                    onClick={() => {
                                        if (page !== 1) {
                                            setPage(page - 1);
                                        }
                                    }}
                                    aria-label="Previous"
                                >
                                    <svg
                                        aria-hidden="true"
                                        className="w-4 h-4 fill-current"
                                        viewBox="0 0 20 20"
                                    >
                                        <path
                                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                            fillRule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                            </li>
                            <li>
                                <button
                                    className={
                                        "px-3 py-1 text-white transition-colors duration-150 bg-purple-600 border border-r-0 border-purple-600 rounded-md focus:outline-none focus:shadow-outline-purple"
                                    }
                                >
                                    {page}
                                </button>
                            </li>
                            <li>
                                <button
                                    className="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple"
                                    onClick={() => {
                                        if (brandList.length > 19) {
                                            setPage(page + 1);
                                        }
                                    }}
                                    aria-label="Next"
                                >
                                    <svg
                                        className="w-4 h-4 fill-current"
                                        aria-hidden="true"
                                        viewBox="0 0 20 20"
                                    >
                                        <path
                                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                            clipRule="evenodd"
                                            fillRule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </span>
            </div>
        </div>
    );
};

export default BrandApplicationTable;
