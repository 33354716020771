import { Switch, Route, Redirect } from "react-router-dom";

// components
import Admin from "./admin";
import BrandApplicationsTab from "./Brand";
import BrandTab from "./Brand/BrandInfo";
import BrandInfoTab from "./Brand/BrandInfo/SingleBrand";
import BrandApplicationTab from "./Brand/SingleBrand";
// import Content from "./content";
import ContentApplicationsTab from "./content/contentApplication";
import Login from "./Login";
import Signup from "./Signup";
import UserTab from "./User";
import ContentApplication from "./content/contentApplication/ContentApplication";
import { useSelector } from "react-redux";
import InviteTab from "./invites";
import SettingsPage from "./settings";
import ForgotPasswordPage from "./forgotPassword";

let Routes = () => {
    const user = useSelector((state: any) => state.user.user);
    return (
        <Switch>
            <Route
                path="/"
                exact={true}
                render={() => (!user ? <Redirect to="/login" /> : <Admin />)}
            />
            <Route
                path="/login"
                exact={true}
                render={() => (user ? <Redirect to="/" /> : <Login />)}
            />
            <Route path="/signup" render={() => (user ? <Redirect to="/" /> : <Signup />)} />
            <Route
                path="/user"
                exact={true}
                render={() => (!user ? <Redirect to="/login" /> : <UserTab />)}
            />
            <Route
                path="/invites"
                exact={true}
                render={() => (!user ? <Redirect to="/login" /> : <InviteTab />)}
            />
            <Route
                path="/brand"
                exact={true}
                render={() => (!user ? <Redirect to="/login" /> : <BrandTab />)}
            />
            <Route
                path="/brand/:id"
                render={() => (!user ? <Redirect to="/login" /> : <BrandInfoTab />)}
            />
            <Route
                path="/brand-application"
                exact={true}
                render={() => (!user ? <Redirect to="/login" /> : <BrandApplicationsTab />)}
            />
            <Route
                path="/brand-application/:id"
                render={() => (!user ? <Redirect to="/login" /> : <BrandApplicationTab />)}
            />
            <Route
                path="/content"
                exact={true}
                render={() => (!user ? <Redirect to="/login" /> : <ContentApplicationsTab />)}
            />
            <Route
                path="/content/:id"
                render={() => (!user ? <Redirect to="/login" /> : <ContentApplication />)}
            />
            <Route
                path="/settings"
                render={() => (!user ? <Redirect to="/login" /> : <SettingsPage />)}
            />
            <Route
                path="/forgot-password"
                exact={true}
                render={() => (user ? <Redirect to="/" /> : <ForgotPasswordPage />)}
            />
        </Switch>
    );
};

export default Routes;
