import ReactECharts from "echarts-for-react";

const LineChart = () => {
    const options = {
        xAxis: {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
            type: "value",
        },
        series: [
            {
                data: [150, 230, 224, 218, 135, 147, 260],
                type: "line",
            },
        ],
    };

    if (!options) return <div> Loading....</div>;

    return (
        <div className="min-w-0 p-4 bg-white rounded-lg shadow flex flex-col items-center">
            <div className="flex justify-items-start w-full">
                <h4 className="mb-1 font-semibold text-gray-800">Line Chart</h4>
            </div>

            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        display: " block",
                        width: "397px",
                        height: "280px",
                    }}
                />
            )}
        </div>
    );
};

export default LineChart;
