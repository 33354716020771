import gql from "graphql-tag";

// Query to get list of brands
export const getBrandListQuery = gql`
    query ($sortByCreatedAt: String!, $pageNo: Int!, $pageSize: Int!) {
        getBrandList(sortByCreatedAt: $sortByCreatedAt, pageNo: $pageNo, pageSize: $pageSize) {
            ok
            error {
                name
                message
                statusCode
            }
            brands {
                brandName
                brandId
                createdAt
                brandType
                nftSubmitted
            }
        }
    }
`;

// Query to get list of employees
export const getEmployeeListQuery = gql`
    query ($sortByCreatedAt: String!, $pageNo: Int!, $pageSize: Int!) {
        getEmployeeList(sortByCreatedAt: $sortByCreatedAt, pageNo: $pageNo, pageSize: $pageSize) {
            ok
            error {
                name
                message
                statusCode
            }
            employee {
                firstName
                lastName
                role
                createdAt
                active
                email
                employeeId
            }
        }
    }
`;

// Query to get brand details
export const GetBrandDetails = gql`
    query ($brandId: String) {
        getBrandForBrandOrAdmin(brandId: $brandId) {
            ok
            error {
                name
                message
                statusCode
            }
            brand {
                brandId
                brandName
                ledgerName
                profilePic
                coverPic
                applicationId
                websiteUrl
                genres
                teamSize
                foundingDate
                brandDescription
                addresses {
                    type
                    lineOne
                    lineTwo
                    city
                    state
                    country
                    zipcode
                }
                onlyCompanyAddress
                createdAt
                updatedAt
                totalNfts
                approvedNfts
                pendingNfts
                rejectedNfts
                totalRevenue
                totalCohorts
            }
        }
    }
`;
