import { useState } from "react";
import { useMutation } from "@apollo/client";
import { DeleteInvite } from "src/graphql/mutation/auth";
import Cross from "../shared/icons/cross";

interface Props {
    setShow: Function;
    email: string;
    inviteId: string;
}

const DeleteInviteModal = (props: Props) => {
    const { setShow, email, inviteId } = props;
    const [error, setError] = useState(null as any);

    const DeleteMutation = useMutation(DeleteInvite)[0];
    const deleteInvite = async () => {
        try {
            const res = await DeleteMutation({
                variables: { email: email },
            });

            const { deleteInvite } = res.data;
            const { ok, error } = deleteInvite;
            if (ok) {
                setShow(false);
                window.location.reload();
            } else {
                setError(error?.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleSubmit = () => {
        deleteInvite();
    };

    return (
        <div className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
            <div
                className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:w-96"
                role="dialog"
                id="modal"
            >
                <header className="flex justify-end">
                    <button
                        className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                        aria-label="close"
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        <Cross />
                    </button>
                </header>

                <div className="mt-4 mb-6">
                    <p className="mb-2 text-center text-lg font-semibold text-gray-700 dark:text-gray-300">
                        Delete Invite
                    </p>
                    <p className="text-center">Are you sure you want to delete this invite ?</p>
                    <div className="text-center">Email: {email}</div>

                    {error ? <div className="text-sm text-red-600 mt-2">{error}</div> : null}
                    <div className="flex">
                        <button
                            type="submit"
                            onClick={() => {
                                handleSubmit();
                            }}
                            className="block w-full px-4 py-2 mt-4 text-base font-medium leading-5 text-center text-white bg-red-600 border border-transparent rounded-lg  hover:bg-red-700"
                        >
                            Delete Invite
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default DeleteInviteModal;
