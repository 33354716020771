import { useState } from "react";
import Sidebar from "../admin/Sidebar";
import InviteUserModal from "../User/inviteUser";
import Table from "./Table";

const InviteTab = () => {
    const [showInvite, setShow] = useState(false);
    return (
        <div className="flex flex-row flex-1 w-full h-full">
            {showInvite && <InviteUserModal setShow={setShow} />}
            <Sidebar route="invites" />
            <div className="flex flex-col pt-5 w-full flex-1 bg-gray-50">
                <div className="flex items-center justify-between mx-6">
                    <h1 className="p-2 text-xl font-semibold">Invites</h1>
                    <button
                        onClick={() => setShow(true)}
                        className="bg-green-600 text-sm text-white rounded-md p-2"
                    >
                        Invite new user
                    </button>
                </div>
                <main className="h-full overflow-y-auto ">
                    <div className="container min-h-full px-6 mx-auto grid">
                        <Table />
                    </div>
                </main>
            </div>
        </div>
    );
};

export default InviteTab;
