import gql from "graphql-tag";

export const GetUserData = gql`
    query {
        getEmployeeDetails {
            ok
            error {
                name
                message
                statusCode
            }
            employee {
                employeeId
                email
                role
                lastName
                firstName
                createdAt
                updatedAt
            }
        }
    }
`;
