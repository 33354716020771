/**
 *
 * Admin sidebar
 */

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setUserData } from "src/redux/actions/action";
import Logo from "../shared/icons/logo";
import SettingsIcon from "../shared/icons/settingsIcon";

interface Props {
    route?: string;
}

const Sidebar = ({ route = "dashboard" }: Props) => {
    const user = useSelector((state: any) => state.user.user);
    return (
        <aside className="admin-sidebar z-20  hidden w-64 overflow-y-auto bg-blue-dark dark:bg-gray-800 md:flex flex-shrink-0 flex-col justify-between h-full">
            <div className="py-4 text-white dark:text-gray-400 flex-1 flex flex-col">
                <Link className="ml-4 pt-3 text-lg font-bold text-white dark:text-gray-200" to="/">
                    <Logo />
                </Link>
                <ul className="mt-6">
                    <li className="relative px-6 py-3">
                        {route === "dashboard" && (
                            <span
                                className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                                aria-hidden="true"
                            ></span>
                        )}
                        <Link
                            className="inline-flex items-center w-full text-sm font-semibold hover:text-white transition-colors duration-150 text-gray-300 dark:hover:text-gray-200 dark:text-gray-100"
                            to="/"
                        >
                            <svg
                                className="w-5 h-5"
                                aria-hidden="true"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                            </svg>
                            <span className="ml-4">Dashboard</span>
                        </Link>
                    </li>
                </ul>
                <ul>
                    <li className="relative px-6 py-3">
                        <Link
                            className="inline-flex items-center hover:text-white w-full text-sm font-semibold transition-colors duration-150 text-gray-300 dark:hover:text-gray-200"
                            to="/user"
                        >
                            {route === "user" && (
                                <span
                                    className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                                    aria-hidden="true"
                                ></span>
                            )}
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <ellipse
                                    cx="16"
                                    cy="9.00008"
                                    rx="5.33333"
                                    ry="5.33333"
                                    fill="currentColor"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3.64465 26.1666C6.57921 22.6044 11.0243 20.3333 15.9999 20.3333C20.9755 20.3333 25.4207 22.6044 28.3552 26.1666C25.4207 29.7288 20.9756 31.9999 16 31.9999C11.0244 31.9999 6.5792 29.7287 3.64465 26.1666Z"
                                    fill="currentColor"
                                />
                            </svg>
                            <span className="ml-4">Users</span>
                        </Link>
                    </li>
                    <li className="relative px-6 py-3">
                        <Link
                            className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 text-gray-300 hover:text-white dark:hover:text-gray-200"
                            to="/invites"
                        >
                            {route === "invites" && (
                                <span
                                    className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                                    aria-hidden="true"
                                ></span>
                            )}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="20"
                                width="20"
                                viewBox="0 0 30 30"
                            >
                                <path
                                    fill="currentColor"
                                    d="M28,5H2A1,1,0,0,0,1,6V24a1,1,0,0,0,1,1H28a1,1,0,0,0,1-1V6A1,1,0,0,0,28,5ZM26.36,7,15,15.738,3.64,7ZM3,23V9.031l11.391,8.762a1,1,0,0,0,1.218,0L27,9.031V23Z"
                                ></path>
                            </svg>
                            <span className="ml-4">Invites</span>
                        </Link>
                    </li>
                    <li className="relative px-6 py-3">
                        <div className="hasSub">
                            <div className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 text-gray-300 hover:text-white dark:hover:text-gray-200">
                                {(route === "brand" || route === "brand-application") && (
                                    <span
                                        className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                                        aria-hidden="true"
                                    ></span>
                                )}
                                <svg
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path>
                                </svg>
                                <span className="ml-4">Brands</span>
                            </div>
                            <div
                                className={
                                    route === "brand" || route === "brand-application" ? "" : "sub"
                                }
                            >
                                <Link
                                    className=" mt-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 text-gray-300 hover:text-white dark:hover:text-gray-200"
                                    to="/brand"
                                >
                                    <span className="ml-4">Brand List</span>
                                </Link>
                                <Link
                                    className=" mt-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 text-gray-300 hover:text-white dark:hover:text-gray-200"
                                    to="/brand-application"
                                >
                                    <span className="ml-4">Brand Applications</span>
                                </Link>
                            </div>
                        </div>
                    </li>
                    <li className="relative px-6 py-3">
                        {route === "content" && (
                            <span
                                className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                                aria-hidden="true"
                            ></span>
                        )}
                        <Link
                            className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 text-gray-300 hover:text-white dark:hover:text-gray-200"
                            to="/content"
                        >
                            <svg
                                className="w-5 h-5"
                                aria-hidden="true"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
                            </svg>
                            <span className="ml-4">Content</span>
                        </Link>
                    </li>
                    <li className="relative px-6 py-3">
                        {route === "settings" && (
                            <span
                                className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                                aria-hidden="true"
                            ></span>
                        )}
                        <Link
                            className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 text-gray-300 hover:text-white dark:hover:text-gray-200"
                            to="/settings"
                        >
                            <SettingsIcon />
                            <span className="ml-4">Settings</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="px-6 my-6">
                <p className="text-white text-center mb-1">{user?.email}</p>
                <p className="text-white text-center uppercase mb-1">{user?.role}</p>
                <button
                    onClick={() => {
                        localStorage.removeItem("accessToken");
                        localStorage.removeItem("refreshToken");
                        setUserData(null);
                        window.location.reload();
                    }}
                    className=" p-3 py-1 bg-red-700 w-full text-white align-middle rounded-md focus:shadow-outline-blue focus:outline-none"
                >
                    Logout
                </button>
            </div>
        </aside>
    );
};

export default Sidebar;
