import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useHistory } from "react-router";
import { UpdateNFTStatus } from "src/graphql/mutation/content";

interface Props {
    setModal: Function;
    id: string;
}
const PrivateModal = (props: Props) => {
    const { setModal, id } = props;
    let history = useHistory();
    const PrivateMutation = useMutation(UpdateNFTStatus)[0];
    const [password, setPassword] = useState("");
    const handlePassword = (e: any) => {
        const { value } = e.target;
        setPassword(value);
    };
    const [error, setError] = useState(null as any);
    const Private = async () => {
        try {
            const res = await PrivateMutation({
                variables: {
                    nftId: id,
                    status: "private",
                    reason: "",
                    password: password,
                },
            });
            const { updateNftStatus } = res.data;
            const { ok, error } = updateNftStatus;
            if (ok) {
                setModal(false);
                history.push("/content");
            } else {
                setError(error?.message);
            }
        } catch (err) {
            console.log(err);
            // setError(err);
        }
    };
    return (
        <div className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
            <div
                // @click.away="closeModal"
                // @keydown.escape="closeModal"
                className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
                role="dialog"
                id="modal"
            >
                <header className="flex justify-end">
                    <button
                        className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                        aria-label="close"
                        onClick={() => {
                            setModal(false);
                        }}
                    >
                        <svg
                            className="w-4 h-4"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            role="img"
                            aria-hidden="true"
                        >
                            <path
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                                fillRule="evenodd"
                            ></path>
                        </svg>
                    </button>
                </header>

                <div className="mt-4 text-center mb-6 px-6">
                    <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300">
                        Private Confirmation
                    </p>
                    <span className="font-medium">
                        Are you sure if you want to make this content private?
                    </span>
                    <label className="block text-sm mt-6 text-left">
                        <span className="text-gray-700 dark:text-gray-400">Your Password</span>
                        <input
                            className="block p-2 border w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                            placeholder="xxxxxxxxx"
                            type="password"
                            name="password"
                            value={password}
                            onChange={handlePassword}
                        />
                    </label>
                    {error && <div className="text-sm text-red-600 text-left">{error}</div>}
                </div>
                <footer className="flex flex-col items-center justify-center w-full px-6 py-3 -mb-4  sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
                    <button
                        onClick={() => {
                            setModal(false);
                        }}
                        className="flex-1 px-5 py-3 text-sm font-medium leading-5 text-white text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 sm:px-4 sm:py-2  active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={() => {
                            Private();
                        }}
                        className="flex-1 px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg  sm:px-4 sm:py-2 active:bg-green-600 hover:bg-green-700 focus:outline-none focus:shadow-outline-green"
                    >
                        Make Private
                    </button>
                </footer>
            </div>
        </div>
    );
};
export default PrivateModal;
