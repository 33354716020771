import { SET_USER_DATA } from "../actionType/actionTypes";
import initialState from "../state";

const UserReducer = (state = initialState as any, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER_DATA:
      state = {
        ...state,
        user: payload,
      };
      break;
  }
  if (process.env.NODE_ENV === "development") {
    console.log("STORE UPDATED\n", state);
  }
  return state;
};
export default UserReducer;
