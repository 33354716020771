import gql from "graphql-tag";

export const UpdateBrandStatus = gql`
    mutation ($brandApplicationId: String!, $status: String!) {
        updateBrandApplicationStatus(brandApplicationId: $brandApplicationId, status: $status) {
            ok
            error {
                name
                message
                statusCode
            }
            updated
            approveToken
        }
    }
`;
