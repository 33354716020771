import { useState, useEffect, useRef } from "react";

import { useApolloClient } from "@apollo/client";

import TableItem from "./tableItem";
import { GetAllInvites } from "src/graphql/queries/list";
import ArrowHeadDown from "../shared/icons/arrowHeadDown";
import { useOutsideAlerter } from "src/utils/outsideAlerter";

/**
 *
 * User Table list
 */

const Table = () => {
    const [statusDD, setStatusDD] = useState(false);
    const [statusFilter, setFilter] = useState("All");
    const [roleDD, setRoleDD] = useState(false);
    const [roleFilter, setRole] = useState("All");
    const [invites, setInvites] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [loadingError, setError] = useState<any>("");

    const roleRef = useRef(null);
    const statusRef = useRef(null);

    useOutsideAlerter(roleRef, setRoleDD);
    useOutsideAlerter(statusRef, setStatusDD);

    const client = useApolloClient();

    const roleList = ["All", "Admin", "Super"];
    const statusList = ["All", "Open", "Closed", "Voting", "Accepted", "Rejected"];

    // Function to get invites
    const GetInvites = async () => {
        setLoading(true);
        try {
            const res = await client.query({
                query: GetAllInvites,
                variables: {
                    sortByCreatedAt: "desc",
                    role: roleFilter === "All" ? null : roleFilter.toLowerCase(),
                    status:
                        statusFilter === "Open"
                            ? "open"
                            : statusFilter === "Closed"
                            ? "closed"
                            : statusFilter === "Voting"
                            ? "votingInProgress"
                            : statusFilter === "Accepted"
                            ? "majorityAccepted"
                            : statusFilter === "Rejected"
                            ? "majorityRejected"
                            : null,
                },
                fetchPolicy: "network-only",
            });
            const { getAllInvites } = res.data;
            const { ok, error, invites } = getAllInvites;
            if (ok) {
                setError("");
                setInvites(invites);
            } else {
                setError(error?.message);
            }
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            setError(err?.message);
        }
    };

    useEffect(() => {
        GetInvites();
    }, [roleFilter, statusFilter]);

    if (loading) {
        return <div> Loading... </div>;
    }

    return (
        <div className="w-full overflow-hidden rounded-lg shadow-xs">
            <div className="w-full overflow-x-auto h-full">
                <div className="flex justify-end gap-4 px-4 pt-4">
                    <div
                        onClick={() => setRoleDD(!roleDD)}
                        ref={roleRef}
                        className="relative flex border border-gray-300 rounded-full px-3 py-2 cursor-pointer"
                    >
                        Role : {roleFilter}
                        <span className="ml-2">
                            <ArrowHeadDown />
                        </span>
                        {roleDD && (
                            <div className="absolute bg-white w-full rounded-lg border border-gray-300 left-0 top-11 z-10">
                                {roleList.map((item) => (
                                    <div
                                        key={item}
                                        onClick={() => {
                                            setRole(item);
                                            setRoleDD(false);
                                        }}
                                        className="px-4 py-2 hover:bg-purple-300"
                                    >
                                        {item}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div
                        onClick={() => setStatusDD(!statusDD)}
                        ref={statusRef}
                        className="relative flex border border-gray-300 rounded-full px-3 py-2 cursor-pointer"
                    >
                        Status : {statusFilter}
                        <span className="ml-2">
                            <ArrowHeadDown />
                        </span>
                        {statusDD && (
                            <div className="absolute bg-white w-full rounded-lg border border-gray-300 left-0 top-11 z-10">
                                {statusList.map((item) => (
                                    <div
                                        key={item}
                                        onClick={() => {
                                            setFilter(item);
                                            setStatusDD(false);
                                        }}
                                        className="px-4 py-2 hover:bg-purple-300"
                                    >
                                        {item}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <table className="w-full whitespace-no-wrap">
                    <thead>
                        <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                            <th className="px-4 py-3">Email</th>
                            <th className="px-4 py-3">Role</th>
                            <th className="px-4 py-3">Status</th>
                            <th className="px-4 py-3">Invited on</th>
                            <th className="px-4 py-3">Delete</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800 ">
                        {invites.map((member: any, index: any) => (
                            <TableItem key={index} member={member} />
                        ))}
                    </tbody>
                    {loadingError && <div className="text-red-600 font-medium">{loadingError}</div>}
                </table>
            </div>
        </div>
    );
};

export default Table;
