import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useHistory } from "react-router";
import Cross from "src/components/shared/icons/cross";
import { UpdateNFTStatus } from "src/graphql/mutation/content";

interface Props {
    setModal: Function;
    id: string;
}
const RejectModal = (props: Props) => {
    const rejectionList = [
        "Copyright Infringement",
        "Unlawful Content",
        "Hateful Content",
        "Explicit Content",
        "Other",
    ];
    const rejectionReason: any = {
        "Copyright Infringement":
            "After going through the NFT submitted by you, we regret to inform you that it has not been approved by our core team for further consideration. It has come to our notice there are copyright issues and the content submitted is not original and is hence being disapproved. ",
        "Unlawful Content":
            "After going through the NFT submitted by you, we regret to inform you that it has not been approved by our core team for further consideration. It has come to our notice that the content submitted doesn't adhere to the law of the land and is hence being disapproved. ",
        "Hateful Content":
            "After going through the NFT submitted by you, we regret to inform you that it has not been approved by our core team for further consideration. The content encourages violence or hatred and is hence being disapproved. ",
        "Explicit Content":
            "After going through the NFT submitted by you, we regret to inform you that it has not been approved by our core team for further consideration. The content submitted is found to have nudity or is sexual in nature and is hence being disapproved.",
    };
    const [otherReason, setOtherReason] = useState({
        description: "",
    });
    const { description } = otherReason;
    // const fieldList = ["Media", "Title", "Description", "Tags", "Thumbnail"];
    const [other, setOther] = useState(false);
    const [rejection, setRej] = useState("") as any;
    const { setModal, id } = props;
    let history = useHistory();
    const [password, setPassword] = useState("");

    const handlePassword = (e: any) => {
        const { value } = e.target;
        setPassword(value);
    };
    const RejectMutation = useMutation(UpdateNFTStatus)[0];
    const reject = async () => {
        try {
            const res = await RejectMutation({
                variables: {
                    nftId: id,
                    status: "rejected",
                    reason:
                        rejection === "Other"
                            ? otherReason.description
                            : rejectionReason[rejection],
                    password: password,
                },
            });
            const { updateNftStatus } = res.data;
            const { ok, error } = updateNftStatus;
            if (ok) {
                setModal(false);
                history.push("/content");
            } else {
                setError(error?.message);
            }
        } catch (err) {
            console.log(err);
            // setError(err);
        }
    };
    const [error, setError] = useState(null as any);

    // const handleAdd = () => {
    //   const a = [...rejections];
    //   a.push({ reason: "Copyright Infringement", fields: [] });
    //   setRej(a);
    // };
    // const handleRemove = (i: number) => {
    //   const a = [...rejections];
    //   a.splice(i, 1);
    //   setRej(a);
    // };
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setOtherReason({ ...otherReason, [name]: value });
    };
    const handleReason = (event: any) => {
        // a[i].reason = event.target.value;
        if (event.target.value === "Other") {
            setOther(true);
            setRej(event.target.value);
        } else {
            if (other) {
                setOther(false);
            }
            setRej(event.target.value);
        }
    };
    // const handleFields = (event: any) => {
    //   // const a = [...rejections];
    //   let values = Array.from(
    //     event.target.selectedOptions,
    //     (option: any) => option.value
    //   ) as string[];

    //   setRej({ ...rejection, fields: values });
    // };
    return (
        <div className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
            <div
                // @click.away="closeModal"
                // @keydown.escape="closeModal"
                className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
                role="dialog"
                id="modal"
            >
                <header className="flex justify-end">
                    <button
                        className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                        aria-label="close"
                        onClick={() => {
                            setModal(false);
                        }}
                    >
                        <Cross />
                    </button>
                </header>

                <div className="mt-4 text-center mb-6">
                    <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300">
                        Rejection Confirmation
                    </p>
                    <span className="font-medium">
                        Are you sure if you want to reject this application?
                    </span>
                </div>
                <div className="my-4">
                    <div className="font-medium">Reason for Rejection:</div>

                    <div className="py-4">
                        <label htmlFor="reason">Reason</label>{" "}
                        <select
                            name="reason"
                            value={rejection}
                            onChange={(event) => {
                                handleReason(event);
                            }}
                            className="p-2 rounded block w-full mt-1 border border-gray-400"
                        >
                            <option value="" disabled>
                                -Select-
                            </option>
                            {rejectionList.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                        {other && (
                            <div className="my-3">
                                {/* <label className="block text-sm">
                  <span className="text-gray-700 dark:text-gray-400">
                    Issue Title
                  </span>
                  <textarea
                    className="block resize-none p-2 border w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    placeholder="Type of detected issue"
                    name="title"
                    value={title}
                    onChange={handleChange}
                  />
                </label> */}
                                <label className="block text-sm">
                                    <span className="text-gray-700 dark:text-gray-400">
                                        Issue Description
                                    </span>
                                    <textarea
                                        className="block resize-none p-2 border w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                                        placeholder="Describe the detected issue"
                                        name="description"
                                        required
                                        value={description}
                                        onChange={handleChange}
                                    />
                                </label>
                            </div>
                        )}
                        <label className="block text-sm mt-6">
                            <span className="text-gray-700 dark:text-gray-400">Your Password</span>
                            <input
                                className="block p-2 border w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                                placeholder="xxxxxxxx"
                                type="password"
                                name="password"
                                value={password}
                                onChange={handlePassword}
                            />
                        </label>
                        {error && <div className="text-sm text-red-600 text-left">{error}</div>}
                        {/* <div className="mt-2">
              <div>Which part(s) of nft has this issue?</div>
              <select
                name="fields"
                id=""
                multiple
                className="p-2 rounded block w-full mt-1 border border-gray-400"
                onChange={(event) => {
                  handleFields(event);
                }}
              >
                {fieldList.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div> */}
                    </div>
                </div>

                <footer className="flex flex-col items-center justify-center w-full px-6 py-3 -mb-4  sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
                    <button
                        onClick={() => {
                            setModal(false);
                        }}
                        className="flex-1 px-5 py-3 text-sm font-medium leading-5 text-white text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 sm:px-4 sm:py-2  active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={() => {
                            reject();
                        }}
                        disabled={
                            rejection === "" ||
                            (other === true && otherReason.description.length < 20)
                        }
                        className={
                            rejection === "" ||
                            (other === true && otherReason.description.trim().length < 20)
                                ? "flex-1 px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg  sm:px-4 sm:py-2 active:bg-purple-600 focus:outline-none focus:shadow-outline-purple opacity-50 cursor-not-allowed"
                                : "flex-1 px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg  sm:px-4 sm:py-2 active:bg-purple-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
                        }
                    >
                        Reject
                    </button>
                </footer>
            </div>
        </div>
    );
};
export default RejectModal;
