import gql from "graphql-tag";

export const GetBrandApplication = gql`
    query ($token: String, $applicationId: String) {
        getBrandApplication(token: $token, applicationId: $applicationId) {
            ok
            error {
                name
                message
                statusCode
            }
            brandApplication {
                type
                status
                firstName
                lastName
                designation
                brandName
                email
                mobile
                contentType
                websiteUrl
                sampleWorkUrls
                socialMediaUrls {
                    facebook
                    linkedin
                    twitter
                    instagram
                }
                country
                contentCreationFrequency
                whereDidYouHearAboutUs
                brandApplicationId
            }
        }
    }
`;
export const GetBrandApplications = gql`
    query ($status: String, $limit: Int, $page: Int) {
        getBrandApplications(status: $status, limit: $limit, page: $page) {
            ok
            error {
                name
                message
                statusCode
            }
            brandApplications {
                brandName
                type
                contentType
                brandApplicationId
                status
                createdAt
            }
        }
    }
`;
export const GetNftList = gql`
    query (
        $nftIds: [String]
        $category: String
        $subCategory: String
        $brandId: String
        $brandName: String
        $brandLedgerName: String
        $fromDate: String
        $sortByCreatedAt: String
        $pageNo: Int
        $pageSize: Int
        $status: String
    ) {
        getNftListForBrandOrAdmin(
            nftIds: $nftIds
            category: $category
            subCategory: $subCategory
            brandId: $brandId
            brandName: $brandName
            brandLedgerName: $brandLedgerName
            fromDate: $fromDate
            sortByCreatedAt: $sortByCreatedAt
            pageNo: $pageNo
            pageSize: $pageSize
            status: $status
        ) {
            ok
            error {
                name
                message
                statusCode
            }
            nfts {
                title
                assets {
                    url
                    assetId
                    type
                }
                description
                cohortPercentage
                maxCohorts
                thumbnailUrl
                subCategory
                tags
                brand {
                    brandName
                }
                category
                nftId
                ownership
                updatedAt
                status
                featured
            }
        }
    }
`;
export const GetAllInvites = gql`
    query ($status: String, $role: String, $sortByCreatedAt: String) {
        getAllInvites(status: $status, role: $role, sortByCreatedAt: $sortByCreatedAt) {
            ok
            error {
                name
                message
                statusCode
            }
            invites {
                voteId
                action
                data {
                    email
                    role
                }
                status
                signatures {
                    publicKey
                    vote
                }
                createdAt
                updatedAt
            }
        }
    }
`;
