interface Props {
    size?: string;
    color?: string;
}
const QoinIcon = ({ size, color }: Props) => (
    <svg
        width={size ? size : "24"}
        height={size ? size : "24"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.4791 16.1741C17.2922 16.4144 17.0884 16.6457 16.8676 16.8664C16.6462 17.0878 16.4142 17.2921 16.1733 17.4794L13.897 15.2033L11.835 17.2652L13.3877 18.8179C10.9812 19.395 8.34025 18.7445 6.46208 16.8664C3.58867 13.9932 3.58867 9.33483 6.46208 6.46163C9.33548 3.58842 13.9942 3.58842 16.8676 6.46163C18.7465 8.3404 19.3968 10.9825 18.8185 13.3896L17.2647 11.8359L15.2026 13.8978L17.4791 16.1741ZM20.5396 19.2345C20.3414 19.4664 20.1325 19.6924 19.9131 19.9117C19.6931 20.1317 19.4665 20.3411 19.234 20.5398L20.6323 21.9381L18.5702 24L16.7399 22.1698C12.3959 24.2681 7.02049 23.5154 3.41655 19.9117C-1.13885 15.3567 -1.13885 7.9714 3.41655 3.41632C7.97196 -1.13877 15.3577 -1.13877 19.9131 3.41632C23.5177 7.02066 24.2701 12.397 22.1702 16.7411L23.9999 18.5707L21.9378 20.6326L20.5396 19.2345Z"
            fill={color ? color : "white"}
        />
    </svg>
);
export default QoinIcon;
