import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { AcceptInvite } from "src/graphql/mutation/auth";
interface Props {
    setStep: Function;
    setRecovery: Function;
}
const SignUpForm = (props: Props) => {
    const { setStep, setRecovery } = props;
    const [error, setError] = useState(null) as any;
    const [formData, setForm] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        password: "",
        confirmPassword: "",
    });
    const { firstName, lastName, phone, email, password, confirmPassword } = formData;
    const [token, setToken] = useState("");
    const SignupMutation = useMutation(AcceptInvite)[0];
    const [valid, setValid] = useState(false);
    const [confirmPassError, setConfirmPassError] = useState(false);

    const signup = async () => {
        try {
            const res = await SignupMutation({
                variables: {
                    firstName: firstName,
                    lastName: lastName,
                    password: password,
                    inviteToken: token,
                },
            });

            const { acceptInvite } = res.data;
            const { ok, error, recoveryPhrase } = acceptInvite;
            if (ok) {
                setRecovery(recoveryPhrase);
                setStep(2);
            } else {
                setError(error?.message);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === "phone") {
            setForm((prevState) => {
                return { ...prevState, [name]: value.replace(/[^0-9]/g, "") };
            });
        } else {
            setForm((prevState) => {
                return { ...prevState, [name]: value };
            });
        }
    };
    const handleSubmit = (e: any) => {
        e.preventDefault();
        signup();
    };
    useEffect(() => {
        if (
            firstName.length < 1 ||
            lastName.length < 1 ||
            password.length < 8 ||
            confirmPassword !== password
        ) {
            setValid(false);
        } else {
            setValid(true);
        }
    }, [firstName, lastName, phone, email, password, confirmPassword]);

    useEffect(() => {
        if (confirmPassword.length > 0 && password.length > 0) {
            if (confirmPassword !== password) {
                setConfirmPassError(true);
            } else {
                setConfirmPassError(false);
            }
        } else {
            setConfirmPassError(false);
        }
    }, [password, confirmPassword]);

    const a = useLocation();

    useEffect(() => {
        const b = a.search.split("=");
        setToken(b[1]);
    }, []);
    return (
        <div className="w-full">
            <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                Create account
            </h1>
            <form onSubmit={handleSubmit}>
                <label className="block text-sm">
                    <span className="text-gray-700 dark:text-gray-400">First Name</span>
                    <input
                        className="block p-2 border w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                        placeholder="Jane"
                        name="firstName"
                        value={firstName}
                        onChange={handleChange}
                    />
                </label>
                <label className="block mt-4 text-sm">
                    <span className="text-gray-700 dark:text-gray-400">Last Name</span>
                    <input
                        className="block p-2 border w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                        placeholder="Doe"
                        type="text"
                        name="lastName"
                        value={lastName}
                        onChange={handleChange}
                    />
                </label>
                <label className="block mt-4 text-sm">
                    <span className="text-gray-700 dark:text-gray-400">Password</span>
                    <input
                        className="block p-2 border w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                        placeholder="***************"
                        type="password"
                        name="password"
                        value={password}
                        onChange={handleChange}
                    />
                </label>
                <label className="block mt-4 text-sm">
                    <span className="text-gray-700 dark:text-gray-400">Confirm password</span>
                    <input
                        className="block p-2 border w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                        placeholder="***************"
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleChange}
                    />
                </label>
                {confirmPassError && (
                    <div className="text-sm text-red-600 font-medium">Passwords don't match</div>
                )}
                {error ? <div className="text-sm text-red-600">{error}</div> : null}

                <button
                    disabled={!valid}
                    className="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                    type="submit"
                >
                    Create account
                </button>
            </form>

            <hr className="my-8" />

            <p className="mt-4">
                <a
                    className="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
                    href="./login"
                >
                    Already have an account? Login
                </a>
            </p>
        </div>
    );
};
export default SignUpForm;
