import HOC from "../shared/HOC";
import Info from "./Info";

/**
 *
 * Dashboard admin panel
 */
const Admin = () => {
    return (
        <HOC route="dashboard">
            <Info />
        </HOC>
    );
};

export default Admin;
